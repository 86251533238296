const { mainConfig, SDK_formOfPayments } = require('./variables');
const { Selector } = require('./selector');
const { sdkFetch } = require('./http');
const {
  validateWalletButtonType,
  validateWalletButtonColor,
  validateWalletButtonSizeMode,
  validateWalletButtonLocale,
} = require('./util');

function baseRequest() {
  return Object.assign(
    {},
    {
      apiVersion: mainConfig.google_apiVersion
        ? mainConfig.google_apiVersion
        : 2,
      apiVersionMinor: mainConfig.google_apiVersionMinor
        ? mainConfig.google_apiVersionMinor
        : 0,
    }
  );
}

const allowedCardNetworks = [
  'AMEX',
  'DISCOVER',
  'INTERAC',
  'JCB',
  'MASTERCARD',
  'VISA',
];
const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

function tokenizationSpecification() {
  return Object.assign(
    {},
    {
      type: mainConfig.google_tokenizationSpecificationType
        ? mainConfig.google_tokenizationSpecificationType
        : 'PAYMENT_GATEWAY',
      parameters:
        mainConfig.google_tokenizationSpecificationType == 'DIRECT'
          ? {
            protocolVersion: 'ECv2',
            publicKey: mainConfig.google_publicKey
              ? mainConfig.google_publicKey
              : undefined,
          }
          : {
            gateway: mainConfig.google_gateway
              ? mainConfig.google_gateway
              : undefined,
            gatewayMerchantId: mainConfig.google_gateway_merchant_id
              ? mainConfig.google_gateway_merchant_id
              : undefined,
          },
    }
  );
}

function baseCardPaymentMethod() {
  return Object.assign(
    {},
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: mainConfig.google_allowedCardAuthMethods
          ? mainConfig.google_allowedCardAuthMethods
          : allowedCardAuthMethods,
        allowedCardNetworks: mainConfig.google_allowedCardNetworks
          ? mainConfig.google_allowedCardNetworks
          : allowedCardNetworks,
        allowPrepaidCards: mainConfig.google_allowPrepaidCards
          ? mainConfig.google_allowPrepaidCards
          : true,
        allowCreditCards: mainConfig.google_allowCreditCards
          ? mainConfig.google_allowCreditCards
          : true,
        assuranceDetailsRequired: mainConfig.google_assuranceDetailsRequired
          ? mainConfig.google_assuranceDetailsRequired
          : false,
        billingAddressRequired: mainConfig.google_billingAddressRequired
          ? mainConfig.google_billingAddressRequired
          : false,
        billingAddressParameters: mainConfig.google_billingAddressParameters
          ? mainConfig.google_billingAddressParameters
          : undefined,
      },
    }
  );
}

function cardPaymentMethod() {
  return Object.assign({}, baseCardPaymentMethod(), {
    tokenizationSpecification: tokenizationSpecification(),
  });
}

let paymentsClient = mainConfig.google_paymentsClient
  ? mainConfig.google_paymentsClient
  : null;

function getGoogleIsReadyToPayRequest() {
  return Object.assign({}, baseRequest(), {
    allowedPaymentMethods: [baseCardPaymentMethod()],
  });
}

function getGooglePaymentDataRequest() {
  const paymentDataRequest = Object.assign({}, baseRequest());
  paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
  paymentDataRequest.emailRequired = mainConfig.google_emailRequired
    ? mainConfig.google_emailRequired
    : false;
  paymentDataRequest.merchantInfo = {
    merchantId: mainConfig.google_merchant_id
      ? mainConfig.google_merchant_id
      : undefined,
    merchantName: mainConfig.google_merchant_name
      ? mainConfig.google_merchant_name
      : undefined,
  };
  paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];

  return paymentDataRequest;
}

function getGooglePaymentsClient() {
  if (paymentsClient === null) {
    paymentsClient = new google.payments.api.PaymentsClient({
      environment: mainConfig.google_environment
        ? mainConfig.google_environment
        : undefined,
      paymentDataCallbacks: {
        onPaymentAuthorized: onPaymentAuthorized,
      },
    });
  }
  return paymentsClient;
}

function onPaymentAuthorized(paymentData) {
  return new Promise(function (resolve) {
    Selector.callBackPayment()
      .then(() => {
        processPayment(paymentData, mainConfig, function (outcome) {
          if (outcome.status === '3DS') {
            resolve({ transactionState: 'SUCCESS' });
            Selector.generate3DSPopUp(outcome);
          } else if (outcome.approved) {
            Selector.callBackSuccess(outcome.callback_payload);
            resolve({ transactionState: 'SUCCESS' });
          } else if (!outcome.approved) {
            if (
              outcome.callback_payload &&
              outcome.callback_payload.status == 'canceled'
            ) {
              resolve({
                transactionState: 'ERROR',
                error: {
                  intent: 'PAYMENT_AUTHORIZATION',
                  message: outcome.callback_payload.message || outcome.message,
                  reason: 'OTHER_ERROR',
                },
              });
              Selector.callBackCancel(outcome.callback_payload);
            } else if (
              outcome.callback_payload &&
              outcome.callback_payload.status == 'error'
            ) {
              resolve({
                transactionState: 'ERROR',
                error: {
                  intent: 'PAYMENT_AUTHORIZATION',
                  message: outcome.callback_payload.message || outcome.message,
                  reason: 'OTHER_ERROR',
                },
              });
              Selector.callBackError(outcome.callback_payload);
            }
          } else {
            resolve({
              transactionState: 'ERROR',
              error: {
                intent: 'PAYMENT_AUTHORIZATION',
                message: outcome.message || 'Failed to Fetch',
                reason: 'OTHER_ERROR',
              },
            });
            Selector.callBackError({
              status: 'error',
              form_of_payment: SDK_formOfPayments.GOOGLE_PAY,
              message: outcome.message || 'Failed to Fetch',
              ...outcome,
            });
          }
        });
      })
      .catch(() => {
        resolve({ transactionState: 'SUCCESS' });
      });
  });
}

async function onGooglePayLoaded(documentCheckout) {
  const paymentsClient = getGooglePaymentsClient();
  return await paymentsClient
    .isReadyToPay(getGoogleIsReadyToPayRequest())
    .then(async function (response) {
      if (response.result) {
        return await addGooglePayButton(documentCheckout);
      }
    })
    .catch(function (err) {
      Selector.callBackError({
        status: 'error',
        form_of_payment: SDK_formOfPayments.GOOGLE_PAY,
        message: err.message,
        ...err,
      });
    });
}

async function addGooglePayButton(documentCheckout) {
  const paymentsClient = getGooglePaymentsClient();
  try {
    const button = paymentsClient.createButton({
      buttonColor:
        mainConfig.theme?.googlePay?.buttonColor &&
        validateWalletButtonColor(
          mainConfig.theme.googlePay.buttonColor,
          'googlePay'
        )
          ? mainConfig.theme.googlePay.buttonColor
          : 'default',
      buttonType:
        mainConfig.theme?.googlePay?.buttonType &&
        validateWalletButtonType(
          mainConfig.theme.googlePay.buttonType,
          'googlePay'
        )
          ? mainConfig.theme.googlePay.buttonType
          : 'plain',
      buttonLocale:
        mainConfig.google_buttonLocale &&
        validateWalletButtonLocale(mainConfig.google_buttonLocale, 'googlePay')
          ? mainConfig.google_buttonLocale
          : 'en',
      buttonSizeMode:
        mainConfig.theme?.googlePay?.buttonSizeMode &&
        validateWalletButtonSizeMode(
          mainConfig.theme.googlePay.buttonSizeMode,
          'googlePay'
        )
          ? mainConfig.theme.googlePay.buttonSizeMode
          : 'fill',
      onClick: onGooglePaymentButtonClicked,
      buttonRootNode: documentCheckout,
    });
    return button;
  } catch (err) {
    Selector.callBackError({
      status: 'error',
      form_of_payment: SDK_formOfPayments.GOOGLE_PAY,
      message: err.message,
      ...err,
    });
  }
}

function getGoogleTransactionInfo() {
  let fee_description = undefined;
  if (mainConfig.google_fee > 0) {
    fee_description = {
      label: mainConfig.google_fee_description || 'Fee',
      type: 'LINE_ITEM',
      price: `${parseFloat(mainConfig.google_fee)}`,
      status: 'FINAL',
    };
  }
  return {
    countryCode: mainConfig.google_country_code
      ? mainConfig.google_country_code
      : undefined,
    currencyCode: mainConfig.google_currency_code
      ? mainConfig.google_currency_code
      : undefined,
    totalPriceStatus: mainConfig.google_totalPriceStatus
      ? mainConfig.google_totalPriceStatus
      : 'FINAL',
    totalPrice: mainConfig.google_total_price
      ? `${parseFloat(mainConfig.google_total_price)}`
      : undefined,
    totalPriceLabel: mainConfig.google_totalPriceLabel
      ? mainConfig.google_totalPriceLabel
      : 'Total',
    displayItems:
      fee_description &&
      mainConfig.google_displayItems &&
      mainConfig.google_displayItems.length
        ? [fee_description, ...mainConfig.google_displayItems]
        : fee_description
          ? [fee_description]
          : undefined,
  };
}

function onGooglePaymentButtonClicked() {
  const paymentDataRequest = getGooglePaymentDataRequest();
  const paymentsClient = getGooglePaymentsClient();

  paymentsClient.loadPaymentData(paymentDataRequest).catch((err) => {
    Selector.callBackError({
      status: 'error',
      form_of_payment: SDK_formOfPayments.GOOGLE_PAY,
      message:
        err.message ||
        err.statusMessage ||
        'User closed the Payment Request UI.',
      ...err,
    });
  });
}

function processPayment(paymentData, mainConfig, callback) {
  const body = {
    google_pay_payload: {
      apiVersion: paymentData.apiVersion,
      apiVersionMinor: paymentData.apiVersionMinor,
      paymentMethodData: paymentData.paymentMethodData,
    },
    currency_code: mainConfig.google_currency_code,
    amount: mainConfig.google_total_price,
    session_id: mainConfig.session_id,
    code: mainConfig.google_code,
  };

  sdkFetch(mainConfig.google_payment_url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (response.ok || response.approved) {
        return await response.json();
      } else {
        throw new Error(JSON.stringify(await response.json()));
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      callback(JSON.parse(err.message));
    });
}
exports.onGooglePayLoaded = onGooglePayLoaded;
exports.processPayment = processPayment;
