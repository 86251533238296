const {
  mainConfig,
  cardsSvg,
  elements,
  method,
  SDK_FOPS,
} = require('./variables');
const {
  html,
  htmlModals,
  saveCardBlock,
  saveCards,
  paymentMethodBlock,
  paymentMethod,
  buttonPay,
  buttonApple,
  cardRemoveModal,
  failedModal,
  iframeLoader,
  applePayNotSupportedHtml,
  feesHtml,
  totalAmountHtml,
  buttonStc,
  saveCardCheckbox,
  successModal,
  redirectModal,
  buttonUrPay,
  mobilePayModal,
  mobilePayOTPModal,
  tamaraPaymentMethod,
  installmentsNotAvailable,
  tamaraWidget,
  loadingInstallmentsOptions,
  tabbyWidget,
  buttonCancel,
  rejectTransactionModal,
} = require('./html');
const {
  requiredKeys,
  keysToValidate,
  googleButtonTypes,
  appleButtonTypes,
  gooleButtonSizeMode,
  googleButtonColors,
  appleButtonColors,
  googleButtonLocale,
  globalLanguageCodes,
} = require('./fields');
const { $t } = require('./translate');

const setConfigs = (config, applePay, googlePay, samsungPay) => {
  if (config) {
    if (applePay) {
      // applePay
      if (typeof config.merchant_id != 'undefined') {
        mainConfig.apple_merchant_id = config.merchant_id;
      }
      if (typeof config.amount != 'undefined') {
        mainConfig.apple_amount = config.amount;
      }
      if (typeof config.code != 'undefined') {
        mainConfig.apple_gateway_code = config.code;
      }
      if (typeof config.country_code != 'undefined') {
        mainConfig.apple_country_code = config.country_code;
      }
      if (typeof config.currency_code != 'undefined') {
        mainConfig.apple_currency_code = config.currency_code;
      }
      if (typeof config.fee != 'undefined') {
        mainConfig.apple_fee = config.fee;
      }
      if (typeof config.fee_description != 'undefined') {
        mainConfig.apple_fee_description = config.fee_description;
      }
      if (typeof config.payment_url != 'undefined') {
        mainConfig.apple_payment_url = config.payment_url;
      }
      if (typeof config.shop_name != 'undefined') {
        mainConfig.apple_shop_name = config.shop_name;
      }
      if (typeof config.validation_url != 'undefined') {
        mainConfig.apple_validation_url = config.validation_url;
      }
      //Overriden Configs
      if (typeof config.merchantCapabilities != 'undefined') {
        mainConfig.apple_merchantCapabilities = config.merchantCapabilities;
      }
      if (typeof config.supportedNetworks != 'undefined') {
        mainConfig.apple_supportedNetworks = config.supportedNetworks;
      }
      if (typeof config.supportedCountries != 'undefined') {
        mainConfig.apple_supportedCountries = config.supportedCountries;
      }
      if (typeof config.version != 'undefined') {
        mainConfig.apple_version = config.version;
      }
      if (typeof config.lineItems != 'undefined') {
        mainConfig.apple_lineItems = config.lineItems;
      }
      if (typeof config.total != 'undefined') {
        mainConfig.apple_total = config.total;
      }
      if (typeof config.currencyCode != 'undefined') {
        mainConfig.apple_apple_currency_code = config.currencyCode;
      }
      if (typeof config.countryCode != 'undefined') {
        mainConfig.apple_country_code = config.countryCode;
      }
      if (typeof config.merchantIdentifier != 'undefined') {
        mainConfig.apple_merchant_id = config.merchantIdentifier;
      }
      //Overriden Styles
      if (
        typeof config.buttonLocale != 'undefined' &&
        validateWalletButtonLocale(config.buttonLocale, 'applePay')
      ) {
        mainConfig.apple_buttonLocale = config.buttonLocale;
      }
    } else if (googlePay) {
      if (typeof config.merchant_id != 'undefined') {
        mainConfig.google_merchant_id = config.merchant_id;
      }
      if (typeof config.merchant_name != 'undefined') {
        mainConfig.google_merchant_name = config.merchant_name;
      }
      if (typeof config.gateway != 'undefined') {
        mainConfig.google_gateway = config.gateway;
      }
      if (typeof config.gateway_merchant_id != 'undefined') {
        mainConfig.google_gateway_merchant_id = config.gateway_merchant_id;
      }
      if (typeof config.fee != 'undefined') {
        mainConfig.google_fee = config.fee;
      }
      if (typeof config.fee_description != 'undefined') {
        mainConfig.google_fee_description = config.fee_description;
      }
      if (typeof config.code != 'undefined') {
        mainConfig.google_code = config.code;
      }
      if (typeof config.country_code != 'undefined') {
        mainConfig.google_country_code = config.country_code;
      }
      if (typeof config.currency_code != 'undefined') {
        mainConfig.google_currency_code = config.currency_code;
      }
      if (typeof config.environment != 'undefined') {
        mainConfig.google_environment = config.environment;
      }
      if (typeof config.total_price != 'undefined') {
        mainConfig.google_total_price = config.total_price;
      }
      if (typeof config.payment_url != 'undefined') {
        mainConfig.google_payment_url = config.payment_url;
      }
      //Overriden Configs
      if (typeof config.apiVersion != 'undefined') {
        mainConfig.google_apiVersion = config.apiVersion;
      }
      if (typeof config.apiVersionMinor != 'undefined') {
        mainConfig.google_apiVersionMinor = config.apiVersionMinor;
      }
      if (typeof config.allowedCardNetworks != 'undefined') {
        mainConfig.google_allowedCardNetworks = config.allowedCardNetworks;
      }
      if (typeof config.allowedCardAuthMethods != 'undefined') {
        mainConfig.google_allowedCardAuthMethods =
          config.allowedCardAuthMethods;
      }
      if (typeof config.tokenizationSpecificationType != 'undefined') {
        mainConfig.google_tokenizationSpecificationType =
          config.tokenizationSpecificationType;
      }
      if (typeof config.baseCardPaymentMethodType != 'undefined') {
        mainConfig.google_baseCardPaymentMethodType =
          config.baseCardPaymentMethodType;
      }
      if (typeof config.totalPriceStatus != 'undefined') {
        mainConfig.google_totalPriceStatus = config.totalPriceStatus;
      }
      if (typeof config.totalPriceLabel != 'undefined') {
        mainConfig.google_totalPriceLabel = config.totalPriceLabel;
      }
      if (typeof config.totalPrice != 'undefined') {
        mainConfig.google_total_price = config.google_total_pricex;
      }
      if (typeof config.displayItems != 'undefined') {
        mainConfig.google_displayItems = config.displayItems;
      }
      if (typeof config.gatewayMerchantId != 'undefined') {
        mainConfig.google_gateway_merchant_id = config.gatewayMerchantId;
      }
      if (typeof config.publicKey != 'undefined') {
        mainConfig.google_publicKey = config.publicKey;
      }
      if (typeof config.merchantId != 'undefined') {
        mainConfig.google_merchant_id = config.merchantId;
      }
      if (typeof config.merchantName != 'undefined') {
        mainConfig.google_merchant_name = config.merchantName;
      }
      if (typeof config.emailRequired != 'undefined') {
        mainConfig.google_emailRequired = config.emailRequired;
      }
      if (typeof config.existingPaymentMethodRequired != 'undefined') {
        mainConfig.google_existingPaymentMethodRequired =
          config.existingPaymentMethodRequired;
      }
      if (typeof config.allowPrepaidCards != 'undefined') {
        mainConfig.google_allowPrepaidCards = config.allowPrepaidCards;
      }
      if (typeof config.allowCreditCards != 'undefined') {
        mainConfig.google_allowCreditCards = config.allowCreditCards;
      }
      if (typeof config.billingAddressRequired != 'undefined') {
        mainConfig.google_billingAddressRequired =
          config.billingAddressRequired;
      }
      if (typeof config.billingAddressParameters != 'undefined') {
        mainConfig.google_billingAddressParameters =
          config.billingAddressParameters;
      }
      if (typeof config.assuranceDetailsRequired != 'undefined') {
        mainConfig.google_assuranceDetailsRequired =
          config.assuranceDetailsRequired;
      }
      //Overriden Styles
      if (typeof config.buttonLocale != 'undefined') {
        mainConfig.google_buttonLocale = config.buttonLocale;
      }
    } if (samsungPay) {
      if (typeof config.code !== 'undefined') {
        mainConfig.samsung_code = config.code;
      }
      if (typeof config.environment !== 'undefined') {
        mainConfig.samsung_environment = config.environment;
      }
      if (typeof config.product_id !== 'undefined') {
        mainConfig.samsung_service_id = config.product_id;
      }
      if (typeof config.merchant_name !== 'undefined') {
        mainConfig.samsung_merchant_name = config.merchant_name;
      }
      if (typeof config.total_price !== 'undefined') {
        mainConfig.samsung_total_price = config.total_price;
      }
      if (typeof config.currency_code !== 'undefined') {
        mainConfig.samsung_currency_code = config.currency_code;
      }
      if (typeof config.country_code !== 'undefined') {
        mainConfig.samsung_country_code = config.country_code;
      }
      if (typeof config.fee !== 'undefined') {
        mainConfig.samsung_fee = config.fee;
      }
      if (typeof config.fee_description !== 'undefined') {
        mainConfig.samsung_fee_description = config.fee_description;
      }
      if (typeof config.flow !== 'undefined') {
        mainConfig.samsung_flow = config.flow;
      }
      if (typeof config.payment_url !== 'undefined') {
        mainConfig.samsung_payment_url = config.payment_url;
      }
    } else {
      //sdk
      if (
        typeof config.formsOfPayment != 'undefined' &&
        config.formsOfPayment.length > 0
      ) {
        mainConfig.formsOfPayment = config.formsOfPayment;
      }
      if (typeof config.lang != 'undefined') {
        mainConfig.lang = config.lang;
      }
      if (typeof config.session_id != 'undefined') {
        mainConfig.session_id = config.session_id;
      }
      if (typeof config.merchant_id != 'undefined') {
        mainConfig.merchant_id = config.merchant_id;
      }
      if (typeof config.selector != 'undefined') {
        mainConfig.selector = config.selector;
      }
      if (typeof config.apiKey != 'undefined') {
        mainConfig.apiKey = config.apiKey;
      }
      if (typeof config.render != 'undefined') {
        mainConfig.render = config.render;
      }
      if (typeof config.displayMode != 'undefined') {
        mainConfig.displayMode = config.displayMode;
      }
      if (typeof config.customer_phone != 'undefined') {
        mainConfig.customer_phone = config.customer_phone;
      }
      if (typeof config.theme != 'undefined') {
        mainConfig.theme = config.theme;
      }
      if (typeof config.mobilePayOtpLength != 'undefined') {
        mainConfig.mobilePayOtpLength = config.mobilePayOtpLength;
      }
      if (typeof config.setupPreload != 'undefined') {
        mainConfig.setupPreload = config.setupPreload;
      }
      if (typeof config.payButtonText != 'undefined') {
        mainConfig.payButtonText = config.payButtonText;
      }
      if (typeof config.displayRejectButton != 'undefined') {
        mainConfig.displayRejectButton = config.displayRejectButton;
      } 
      if (typeof config.cancel_url != 'undefined') {
        mainConfig.cancel_url = config.cancel_url;
      }
    }
  }

  if (
    mainConfig.selector &&
    mainConfig.session_id &&
    mainConfig.apiKey &&
    mainConfig.merchant_id
  ) {
    return true;
  } else {
    const missingFields = [];

    if (!mainConfig.selector) missingFields.push('selector');
    if (!mainConfig.session_id) missingFields.push('session_id');
    if (!mainConfig.apiKey) missingFields.push('apiKey');
    if (!mainConfig.merchant_id) missingFields.push('merchant_id');

    console.log(
      `[Error] Failed to initialize SDK, Missing fields: "${missingFields.join(
        ', '
      )}" in Checkout.init()`
    );
    return false;
  }
};

const ccvValidation = (cardCcv) => {
  const data = {};
  const errors = {};
  let error = false;

  if (cardCcv) {
    data.cvv = cardCcv;
  } else {
    errors.tokenCardCcvBlock = $t('This field is required');
    error = true;
  }

  return {
    data: data,
    errors: errors,
    error: error,
  };
};

const phoneNumberSetSpace = (phoneNumber) => {
  if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
    return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3);
  } else if (phoneNumber.length > 6) {
    return (
      phoneNumber.slice(0, 3) +
      ' ' +
      phoneNumber.slice(3, 6) +
      ' ' +
      phoneNumber.slice(6)
    );
  } else {
    return phoneNumber;
  }
};

const getSaveCardsHtml = (cards, currencyCodeDefault, paymentMethods, paymentServices) => {
  let html = '';
  if (cards && cards.length) {
    for (let key = 0; key < cards.length; key++) {
      const card = cards[key];
      let currencyCode = currencyCodeDefault;
      let amount = '';
      let fee = '';
      let description = '';
      if (paymentMethods || paymentServices) {
        const m = paymentMethods.find(({ code }) => code == card.pg_code) || paymentServices.find(({ code }) => code == card.pg_code) ;
        if (m) {
          currencyCode = m.currency_code;
          fee = m.fee;
          amount = m.amount;
          description = m.fee_description;
        }
      }
      let expiry = '';
      if (card.is_expired) {
        if (card.expiry_month && card.expiry_year) {
          expiry =
            $t('Expired on') + ' ' + card.expiry_month + '/' + card.expiry_year;
        } else {
          expiry = $t('Expired');
        }
      } else {
        expiry =
          $t('Expires on') + ' ' + card.expiry_month + '/' + card.expiry_year;
      }

      const expiryClass = card.is_expired ? 'ottu__sdk-red' : '';
      html += saveCards(card.cvv_required)
        .replace(/{%key%}/g, key)
        .replace(/{%logo%}/g, cardsSvg[card.brand])
        .replace(/{%brand%}/g, card.brand)
        .replace(/{%gateway%}/g, card.pg)
        .replace(/{%alt%}/g, card.brand)
        .replace(/{%url%}/g, card.submit_url)
        .replace(/{%token%}/g, card.token)
        .replace(/{%number%}/g, card.number)
        .replace(/{%expiry%}/g, expiry)
        .replace(/{%expiryClass%}/g, expiryClass)
        .replace(/{%isExpired%}/g, card.is_expired)
        .replace(/{%radioClass%}/g, elements.accordionItemRadio)
        .replace(/{%ccvClass%}/g, card.cvv_required ? 'cvv_required' : '')
        .replace(/{%ccv_required%}/g, card.cvv_required)
        .replace(/{%is_preferred%}/g, card.is_preferred)
        .replace(/{%delete_url%}/g, card.delete_url)
        .replace(/{%fee%}/g, fee)
        .replace(/{%description%}/g, description || '')
        .replace(/{%currency_code%}/g, currencyCode)
        .replace(/{%amount%}/g, amount)
        .replace(/{%currency_code_default%}/g, currencyCodeDefault);
    }
  }

  const saveCard = saveCardBlock().replace(/{%content%}/g, html);
  return saveCard;
};

const getPaymentMethodsHtml = (
  paymentMethods = [],
  saveCard,
  currency_code,
  flexMethods = []
) => {
  let html = '';
  if (paymentMethods.length || flexMethods.length) {
    let key = 0;

    for (let index = 0; index < paymentMethods.length; key++, index++) {
      const method = paymentMethods[index];
      let canSaveCard;
      if (typeof saveCard == 'boolean') {
        canSaveCard = saveCard;
      } else if (typeof saveCard == 'undefined') {
        canSaveCard = method.can_save_card ? method.can_save_card : false;
      }

      if (
        (method.flow === 'ottu_pg' && checkFormsofPayments('ottuPG')) ||
        (method.flow === 'redirect' && checkFormsofPayments('redirect'))
      ) {
        html += paymentMethod()
          .replace(/{%savecard%}/g, canSaveCard && !window.redirectSubmitUrl)
          .replace(/{%key%}/g, key)
          .replace(/{%logo%}/g, method.icon)
          .replace(/{%submit_url%}/g, method.submit_url)
          .replace(/{%redirect_url%}/g, method.redirect_url)
          .replace(/{%payment_url%}/g, method.payment_url)
          .replace(/{%cancel_url%}/g, '')
          .replace(/{%flow%}/g, method.flow)
          .replace(/{%code%}/g, method.code)
          .replace(/{%name%}/g, method.name)
          .replace(/{%fee%}/g, method.fee)
          .replace(/{%description%}/g, method.fee_description || '')
          .replace(/{%currency_code%}/g, method.currency_code)
          .replace(/{%amount%}/g, method.amount)
          .replace(/{%currency_code_default%}/g, currency_code);
      }
    }

    for (let index = 0; index < flexMethods.length; key++, index++) {
      const method = flexMethods[index];

      if (!method.pre_payment_options_available) {
        html += loadingInstallmentsOptions()
          .replace(/{%key%}/g, key)
          .replace(/{%fee%}/g, method.fee)
          .replace(/{%flow%}/g, method.flow)
          .replace(/{%code%}/g, method.code)
          .replace(/{%cancel_url%}/g, '')
          .replace(/{%amount%}/g, method.amount)
          .replace(/{%savecard%}/g, method.can_save_card)
          .replace(/{%submit_url%}/g, method.submit_url)
          .replace(/{%redirect_url%}/g, method.redirect_url)
          .replace(/{%payment_url%}/g, method.payment_url)
          .replace(/{%description%}/g, method.fee_description || '')
          .replace(/{%currency_code%}/g, method.currency_code)
          .replace(/{%currency_code_default%}/g, currency_code);
        continue;
      }

      if (method.flow === 'tamara') {
        const name = getInstallmentMethodLabels(method.pre_payment_options)
        html += method.pre_payment_options?.is_available
          ? tamaraPaymentMethod(name, method.amount)
              .replace(/{%key%}/g, key)
              .replace(/{%flow%}/g, method.flow)
              .replace(/{%code%}/g, method.code)
              .replace(/{%fee%}/g, method.fee)
              .replace(/{%submit_url%}/g, method.submit_url)
              .replace(/{%description%}/g, method.fee_description || '')
              .replace(/{%currency_code%}/g, method.currency_code)
              .replace(/{%amount%}/g, method.amount)
              .replace(/{%currency_code_default%}/g, currency_code)
          : installmentsNotAvailable(method.icon, method.flow);
      }

      if (method.flow === 'tabby') {
        html += method.pre_payment_options?.is_available
          ? paymentMethod()
              .replace(/{%key%}/g, key)
              .replace(/{%fee%}/g, method.fee)
              .replace(/{%flow%}/g, method.flow)
              .replace(/{%code%}/g, method.code)
              .replace(/{%name%}/g, $t('Pay in 4 interest-free payments'))
              .replace(/{%logo%}/g, method.icon)
              .replace(/{%cancel_url%}/g, '')
              .replace(/{%amount%}/g, method.amount)
              .replace(/{%savecard%}/g, method.can_save_card)
              .replace(/{%submit_url%}/g, method.submit_url)
              .replace(/{%redirect_url%}/g, method.redirect_url)
              .replace(/{%payment_url%}/g, method.payment_url)
              .replace(/{%description%}/g, method.fee_description || '')
              .replace(/{%currency_code%}/g, method.currency_code)
              .replace(/{%currency_code_default%}/g, currency_code)
          : installmentsNotAvailable(method.icon, method.flow);
      }
    }
  }

  const paymentMethodhtml = html
    ? paymentMethodBlock().replace(/{%content%}/g, html)
    : html;
  return paymentMethodhtml;
};

const getCardRemoveModalHtml = (number) => {
  return cardRemoveModal().replace(/{%number%}/g, number);
};

const getRejectModalHtml = () => {
  return rejectTransactionModal()
};

const getPopupModalHtml = (type, message, response) => {
  let html = '';
  if (response) {
    for (const key in response) {
      if (
        Object.prototype.hasOwnProperty.call(response, key) &&
        response[key]
      ) {
        html += `<p>${key} : ${response[key]}</p>`;
      }
    }
  }

  if (type === 'success') {
    return successModal()
      .replace(/{%message%}/g, message)
      .replace(/{%response%}/g, html);
  } else if (type === 'error') {
    return failedModal()
      .replace(/{%message%}/g, message)
      .replace(/{%response%}/g, html);
  } else if (type === 'redirect') {
    return redirectModal().replace(/{%message%}/g, message);
  }
};

const getFailedModalHtml = (error, heading, buttonText) => {
  return failedModal(heading, buttonText)
    .replace(/{%message%}/g, error || $t('Something Went Wrong'))
    .replace(/{%response%}/g, '');
};

const getMobilePayModal = (mobileNo, canSaveCard, saveCard, type) => {
  if (mobileNo) {
    mobileNo = phoneNumberSetSpace(mobileNo);
  }
  return mobilePayModal(canSaveCard, saveCard, type).replace(
    /{%mobileNo%}/g,
    mobileNo || ''
  );
};

const getTamaraWidget = (name, amount) => {
  return tamaraWidget(name, amount);
};

const getTabbyWidget = (name, logo) => {
  return tabbyWidget(name, logo);
};

const getMobilePayOTPModal = () => {
  return mobilePayOTPModal();
};

const getApplePayNotSupportedHtml = () => {
  return applePayNotSupportedHtml();
};

const getApplePayHtml = (applePay, isWalletActive) => {
  if (applePay) {
    return buttonApple(isWalletActive);
  }
};

const getWalletButtonsHtml = (method) => {
  let button;
  if (method && method.flow == SDK_FOPS.STC_PAY) {
    button = buttonStc();
  } else if (method && method.flow == SDK_FOPS.UR_PAY) {
    button = buttonUrPay();
  }

  return button
    .replace(/{%savecard%}/g, method.can_save_card)
    .replace(/{%submit_url%}/g, method.submit_url)
    .replace(/{%payment_url%}/g, method.payment_url)
    .replace(/{%flow%}/g, method.flow)
    .replace(/{%code%}/g, method.code)
    .replace(/{%name%}/g, method.name)
    .replace(/{%fee%}/g, method.fee)
    .replace(/{%description%}/g, method.fee_description || '')
    .replace(/{%currency_code%}/g, method.currency_code)
    .replace(/{%amount%}/g, method.amount);
};

const getSaveCardCheckbox = (canSaveCard) => {
  if (canSaveCard === 'true') {
    return saveCardCheckbox();
  }

  return '';
};

const getFeesHtml = () => {
  return feesHtml();
};

const getTotalAmountHtml = () => {
  return totalAmountHtml();
};

const getButtonsHtml = (cancel) => {
  let html = buttonPay(); 
  if (cancel) {
    html += buttonCancel();
  }
  return html;
};

const getHtml = (displayMode) => {
  return html(displayMode);
};

const getHtmlErorrModal = () => {
  return htmlModals();
};

const getIframeLoader = () => {
  return iframeLoader();
};

const checkSaveCardsExist = (data) => {
  if (data && data.customer_id && data.cards && data.cards.length) {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatus3ds = (response) => {
  if (
    response &&
    response.status == '3DS' &&
    (response.redirect_url ||
      (response.html && response.ws_url && response.reference_number))
  ) {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusSuccess = (response) => {
  if (response && response.status == 'success') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusCanceled = (response) => {
  if (response && response.status == 'canceled') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusError = (response) => {
  if (response && response.status == 'error') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusFailed = (response) => {
  if (response && response.status == 'failed') {
    return true;
  } else {
    return false;
  }
};

const checkRender = (config) => {
  if (config && config.render) {
    return true;
  } else {
    return false;
  }
};

const setMethod = (
  type,
  code,
  submit_url,
  cancel_url,
  redirect_url,
  payment_url,
  id,
  ccv_required,
  can_save_card,
  amount,
  currency_code,
  pg
) => {
  method.type = type;
  method.code = code;
  method.submit_url = submit_url;
  method.cancel_url = cancel_url ? cancel_url : '';
  method.redirect_url = redirect_url ? redirect_url : '';
  method.payment_url = payment_url ? payment_url : '';
  method.id = id ? id : '';
  method.ccv_required = ccv_required ? ccv_required : false;
  method.can_save_card = can_save_card ? can_save_card : false;
  method.amount = amount ? amount : '';
  method.currency_code = currency_code ? currency_code : '';
  method.pg = pg ? pg : '';
  return true;
};

const validateMobilePayFields = (value, type) => {
  if (!value) {
    return false;
  } else if (value && type == 'phoneNo') {
    const phoneNoRegex = new RegExp(/^(?:\d{10}|\d{12})$/);
    return phoneNoRegex.test(value);
  } else if (value && type == 'otp') {
    const otpRegex = new RegExp(
      `^(?:\\D*\\d){${mainConfig.mobilePayOtpLength || 1}}\\D*$`
    );
    return otpRegex.test(value);
  }
};

const setEventListenerForRadioButtons = (elements) => {
  for (let i = 0; i < elements.length; i++) {
    if (!elements[i]) {
      continue;
    }

    elements[i].addEventListener('keydown', (e) => {
      if (e.which === 13) {
        elements[i].blur();
      }
    });

    elements[i].addEventListener('input', (e) => {
      let ccvLength = 4;
      const brandName = elements[i].attributes['data-brand']
        ? elements[i].attributes['data-brand'].value
        : '';

      if (brandName === 'AMEX') {
        ccvLength = 4;
      } else {
        ccvLength = 3;
      }

      const value = e.target.value.replace(/[^0-9]+/g, '').slice(0, ccvLength);

      e.target.value = value;
      elements[i].classList.remove('ottu__sdk-invalid-input');

      if (elements[i].closest('.ottu__sdk-invalid-input')) {
        elements[i]
          .closest('.ottu__sdk-invalid-input')
          .classList.remove('ottu__sdk-invalid-input');
      }
    });
  }
};

const resetAllSavedCards = (elements) => {
  elements.forEach((element) => {
    if (element.closest('.ottu__sdk-invalid-input')) {
      element
        .closest('.ottu__sdk-invalid-input')
        .classList.remove('ottu__sdk-invalid-input');
    }

    element.value = '';
  });
};

const resetSelectedCheckboxes = (elements, checkbox) => {
  if (elements) {
    elements.forEach((element) => {
      if (element !== checkbox) {
        if (element.checked) element.checked = false;
      }
    });
  }
};

const checkFormsofPayments = (form) => {
  if (mainConfig.formsOfPayment && mainConfig.formsOfPayment.length)
    return mainConfig.formsOfPayment.includes(form) ? true : false;
};

const replaceSdk = (configs) => {
  let oldCheckout = document.getElementById(configs.selector);
  let newCheckout = document.createElement('div');
  newCheckout.setAttribute('id', configs.selector);
  oldCheckout.parentElement.replaceChild(newCheckout, oldCheckout);
};

const getCssTheme = (theme) => {
  const cssClasses = Object.entries(theme)
    .map(([className, properties]) => {
      const cssClassProperties = Object.entries(properties)
        .map(([property, value]) => `${property}: ${value} !important;`)
        .join('\n');
      return `.ottu__sdk-${className}-theme {
      ${cssClassProperties}
    }`;
    })
    .join('\n\n');

  let sheet = document.createElement('style');
  sheet.innerHTML = cssClasses;
  sheet = handleOverridenStyles(sheet);
  return sheet;
};

const filterStyles = (theme, allowedProps) => {
  if (theme) {
    const allowedProperties = allowedProps;
    const filteredObject = {};

    for (const key in theme) {
      if (allowedProperties.includes(key)) {
        filteredObject[key] = theme[key];
      }
    }
    return filteredObject;
  } else {
    return {};
  }
};

const handleOverridenStyles = (sheet) => {
  if (mainConfig.theme['selected-method']) {
    mainConfig.theme['selected-method'] = filterStyles(
      mainConfig.theme['selected-method'],
      ['background', 'border']
    );
    sheet.innerHTML += `.ottu__sdk-accordion-item.ottu__sdk-border-green { background: ${mainConfig.theme['selected-method']['background']} !important; border: ${mainConfig.theme['selected-method']['border']} !important; } input[type='radio']:checked + label { border: ${mainConfig.theme['selected-method']['border']}; background: ${mainConfig.theme['selected-method']['background']} !important; }`;
  }
  if (mainConfig.theme['selected-checkbox']) {
    sheet.innerHTML += `input:checked + .slider { background: ${mainConfig.theme['selected-checkbox']['background']}`;
  }
  return sheet;
};

const handleApplePayStyles = () => {
  let sheet = document.createElement('style');
  if (
    mainConfig.theme?.applePay?.buttonType &&
    validateWalletButtonType(mainConfig.theme.applePay.buttonType, 'applePay')
  ) {
    sheet.innerHTML = `.ottu__sdk-apple-pay-button-type {-apple-pay-button-type: ${
      mainConfig.theme.applePay.buttonType || 'plain'
    }}`;
  }
  if (
    mainConfig.theme?.applePay?.buttonColor &&
    validateWalletButtonColor(mainConfig.theme.applePay.buttonColor, 'applePay')
  ) {
    sheet.innerHTML += `.ottu__sdk-apple-pay-button-style {-apple-pay-button-style: ${
      mainConfig.theme.applePay.buttonColor || 'black'
    }}`;
  }
  return sheet;
};

const calculateSubTotal = (amount, fee) => {
  let subTotal = amount - fee;
  if (amount.includes('.')) {
    let decimalsDigits = amount.split('.')[1].length;
    return subTotal.toFixed(decimalsDigits);
  }
  return subTotal;
};

const validateSetupPreload = (preloadData) => {
  if (isObjectEmpty(preloadData)) {
    return false;
  }

  const validateObject = (obj, keys) => {
    for (const key of keys) {
      if (!(key in obj)) {
        return false;
      }
    }
    return true;
  };

  const validateArrayObjects = (val, keys) => {
    if (Array.isArray(val)) {
      for (const obj of val) {
        if (!validateObject(obj, keys)) {
          return false;
        }
      }
      return true;
    } else {
      if (!validateObject(val, keys)) {
        return false;
      }
      return true;
    }
  };

  const validatePaymentMethods = (methods, keys) => {
    if (!Array.isArray(methods)) {
      return false;
    }

    return methods.every((method) => {
      if (!validateObject(method, keys)) {
        return false;
      }

      if (method.flow === SDK_FOPS.OTTU_PG) {
        return !!method.redirect_url;
      } else if (
        method.flow === SDK_FOPS.STC_PAY ||
        method.flow === SDK_FOPS.UR_PAY
      ) {
        return !!method.payment_url && !!method.submit_url;
      } else {
        return !!method.submit_url;
      }
    });
  };

  const validatePaymentServices = (services) => {
    if (!Array.isArray(services)) {
      return false;
    }
    return services.every((service) => {
      if (service.flow == SDK_FOPS.GOOGLE_PAY) {
        return validateObject(service, keysToValidate['google_pay_config']);
      } else if (service.flow == SDK_FOPS.APPLE_PAY) {
        return validateObject(service, keysToValidate['apple_pay_config']);
      } else if (
        service.flow == SDK_FOPS.STC_PAY ||
        service.flow == SDK_FOPS.UR_PAY
      ) {
        return validateObject(service, [
          'payment_url',
          'submit_url',
          ...keysToValidate['payment_methods'],
        ]);
      }

      return false;
    });
  };

  return requiredKeys.every((key) => {
    switch (key) {
      case 'cards':
      case 'google_pay_config':
      case 'apple_pay_config':
        return (
          !preloadData[key] ||
          Object.keys(preloadData[key]).length === 0 ||
          validateArrayObjects(preloadData[key], keysToValidate[key])
        );
      case 'apple_pay_available':
      case 'google_pay_available':
        return (
          !preloadData[key] ||
          preloadData[key] === true ||
          preloadData[key] === false
        );
      case 'payment_methods':
        return (
          preloadData[key] &&
          (preloadData[key].length === 0 ||
            validatePaymentMethods(preloadData[key], keysToValidate[key]))
        );
      case 'payment_services':
        return (
          !preloadData[key] ||
          preloadData[key].length === 0 ||
          validatePaymentServices(preloadData[key])
        );
      default:
        return Object.hasOwnProperty.call(preloadData, key);
    }
  });
};

const findPaymentConfig = (methods, methodFlow, methodName) => {
  if (methods && (methodFlow || methodName)) {
    return methods.find(({ flow, name }) => {
      return (
        (!methodFlow || flow.toLowerCase() === methodFlow) &&
        (!methodName || name.toLowerCase() === methodName)
      );
    });
  }
};

const getFormOfPayment = (type) => {
  if (type === 'stcPay') {
    return SDK_FOPS.STC_PAY;
  }
  if (type === 'urPay') {
    return SDK_FOPS.UR_PAY;
  }
};

const handleMobilePayStyles = (type) => {
  let sheet = document.createElement('style');
  if (type === 'stcPay' && mainConfig.theme?.stcPay?.buttonColor) {
    sheet.innerHTML = `.ottu__sdk-stcPay-theme { background: ${
      mainConfig.theme.stcPay.buttonColor || 'white'
    } !important}`;
  }
  if (type === 'urPay' && mainConfig.theme?.urPay?.buttonColor) {
    sheet.innerHTML += `.ottu__sdk-urPay-theme { background: ${
      mainConfig.theme.urPay.buttonColor || 'white'
    } !important}`;
  }
  return sheet;
};

const validateWalletButtonType = (value, type) => {
  if (type === 'googlePay') {
    return googleButtonTypes.includes(value);
  }
  if (type === 'applePay') {
    return appleButtonTypes.includes(value);
  }
};

const validateWalletButtonColor = (value, type) => {
  if (type === 'googlePay') {
    return googleButtonColors.includes(value);
  }
  if (type === 'applePay') {
    return appleButtonColors.includes(value);
  }
};

const validateWalletButtonSizeMode = (value, type) => {
  if (type === 'googlePay') {
    return gooleButtonSizeMode.includes(value);
  }
};

const validateWalletButtonLocale = (value, type) => {
  if (type === 'googlePay') {
    return googleButtonLocale.includes(value);
  }
  if (type == 'applePay') {
    return globalLanguageCodes.includes(value);
  }
};

const isObjectEmpty = (object = {}) => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

const isOnlyApplePay = (data) => {
  const { payment_services, apple_pay_available, google_pay_available } = data;
  const applePayService = findPaymentConfig(payment_services, 'apple_pay');

  if (
    !google_pay_available &&
    ((payment_services?.length === 0 && apple_pay_available) ||
      (payment_services?.length === 1 && applePayService))
  ) {
    return true;
  }

  return false;
};

const getInstallmentMethodLabels = (configs, defaultName) => {
  if (!configs?.payment_labels?.length) {
    return defaultName;
  }

  const paymentLabel = configs.payment_labels[0];

  if (mainConfig.lang === 'en') {
    return paymentLabel.description_en || defaultName;
  }

  if (mainConfig.lang === 'ar') {
    return paymentLabel.description_ar || defaultName;
  }
};

const loadCSS = (element) => {
  return new Promise((resolve, reject) => {
    const cssLink = document.createElement('link');
    cssLink.href = process.env.SERVER_URL + '/v3/css/checkout.css';
    cssLink.rel = 'stylesheet';
    cssLink.as = 'style';
    cssLink.onload = resolve;
    cssLink.onerror = reject;

    element.appendChild(cssLink);
  });
};

const generateSamsungOrderNo = (value) => {
  if (!value) {
    return ""
  }

  return value.slice(0, 36);
}

exports.loadCSS = loadCSS;
exports.getInstallmentMethodLabels = getInstallmentMethodLabels;
exports.generateSamsungOrderNo = generateSamsungOrderNo;
exports.findPaymentConfig = findPaymentConfig;
exports.validateSetupPreload = validateSetupPreload;
exports.calculateSubTotal = calculateSubTotal;
exports.handleOverridenStyles = handleOverridenStyles;
exports.filterStyles = filterStyles;
exports.setConfigs = setConfigs;
exports.ccvValidation = ccvValidation;
exports.getHtml = getHtml;
exports.getApplePayNotSupportedHtml = getApplePayNotSupportedHtml;
exports.getSaveCardsHtml = getSaveCardsHtml;
exports.getPaymentMethodsHtml = getPaymentMethodsHtml;
exports.getCardRemoveModalHtml = getCardRemoveModalHtml;
exports.getRejectModalHtml = getRejectModalHtml;
exports.getPopupModalHtml = getPopupModalHtml;
exports.getButtonsHtml = getButtonsHtml;
exports.getApplePayHtml = getApplePayHtml;
exports.getIframeLoader = getIframeLoader;
exports.checkSaveCardsExist = checkSaveCardsExist;
exports.checkResponseStatus3ds = checkResponseStatus3ds;
exports.checkResponseStatusSuccess = checkResponseStatusSuccess;
exports.checkResponseStatusCanceled = checkResponseStatusCanceled;
exports.checkResponseStatusError = checkResponseStatusError;
exports.checkResponseStatusFailed = checkResponseStatusFailed;
exports.checkRender = checkRender;
exports.setMethod = setMethod;
exports.getHtmlErorrModal = getHtmlErorrModal;
exports.resetAllSavedCards = resetAllSavedCards;
exports.setEventListenerForRadioButtons = setEventListenerForRadioButtons;
exports.checkFormsofPayments = checkFormsofPayments;
exports.replaceSdk = replaceSdk;
exports.resetSelectedCheckboxes = resetSelectedCheckboxes;
exports.getMobilePayModal = getMobilePayModal;
exports.getMobilePayOTPModal = getMobilePayOTPModal;
exports.validateMobilePayFields = validateMobilePayFields;
exports.phoneNumberSetSpace = phoneNumberSetSpace;
exports.getSaveCardCheckbox = getSaveCardCheckbox;
exports.getFeesHtml = getFeesHtml;
exports.getTotalAmountHtml = getTotalAmountHtml;
exports.getCssTheme = getCssTheme;
exports.getWalletButtonsHtml = getWalletButtonsHtml;
exports.getFailedModalHtml = getFailedModalHtml;
exports.getFormOfPayment = getFormOfPayment;
exports.handleApplePayStyles = handleApplePayStyles;
exports.validateWalletButtonType = validateWalletButtonType;
exports.validateWalletButtonColor = validateWalletButtonColor;
exports.validateWalletButtonSizeMode = validateWalletButtonSizeMode;
exports.validateWalletButtonLocale = validateWalletButtonLocale;
exports.handleMobilePayStyles = handleMobilePayStyles;
exports.isObjectEmpty = isObjectEmpty;
exports.isOnlyApplePay = isOnlyApplePay;
exports.getTamaraWidget = getTamaraWidget;
exports.getTabbyWidget = getTabbyWidget;
