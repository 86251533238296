exports.mainConfig = {
  merchant_id: '',
  session_id: null,
  lang: 'en',
  render: true,
  selector: '',
  displayMode: 'column',
  shadowRootClosed: true,
  captureAll: false,
  mobilePayOtpCooldownTime: 60,
  payButtonText: {
    'en': 'Pay',
    'ar': 'إدفع'
  }
};

exports.SDK_FOPS = {
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
  STC_PAY: 'stc_pay',
  UR_PAY: 'urpay',
  TOKEN_PAY: 'token_pay',
  REDIRCT: 'redirect',
  OTTU_PG: 'ottu_pg',
  SAMSUNG_PAY: 'samsung_pay',
};

exports.SAMSUNG_PAY_ACTION = {
  cancel: 'user_cancel',
  error: 'payment_error',
};

exports.method = {
  type: '',
  code: '',
  submit_url: '',
  cancel_url: '',
  payment_url: '',
  redirect_url: '',
  id: '',
  ccv_required: '',
  can_save_card: '',
};

exports.elements = {
  sdkChild: 'ottu__sdk-child',
  threeDS: 'threeDs-challenge',
  amount: 'sdk-amount',
  currency: 'sdk-currency',
  blockMethodsLoader: 'sdk-block-methods-loader',
  blockMethods: 'ottu__sdk-block-methods',
  block_not_supported: 'ottu__sdk-not-supported',
  savedCards: 'ottu__sdk-saved-cards',
  savedCardsBlock: 'ottu__sdk-saved-cards-block',
  accordionItem: 'ottu__sdk-accordion-item',
  paymentMethod: 'ottu__sdk-payment-method',
  installmentsNotAvailableBlock: 'ottu__sdk-no-installments-available-block',
  loadingInstallmentsOptionsBlock: 'ottu__sdk-installments-loading-block',
  loadingInstallmentsOptions: 'ottu__sdk-installments-loading',
  accordionItemHeader: 'ottu__sdk-accordion-item-header',
  tamaraWidgetBlock: 'ottu__sdk-tamara',
  tamaraLoader: 'ottu__sdk-tamara-loader',
  tamaraPaymentMethod: 'ottu__sdk-tamara-payment-method',
  installmentsNotAvailable: 'ottu__sdk-no-installments-available',
  accordionItemRadio: 'ottu__sdk-payment-radio',
  payButton: 'pay-button',
  payCancel: 'pay-cancel',
  cardSaveCheckbox: 'ottu__sdk-card-save',
  cardSaveBlock: 'ottu__sdk-card-save-block',
  blockButtonsLoader: 'ottu__sdk-block-buttons-loader',
  blockButtons: 'ottu__sdk-block-buttons',
  blockAppleButton: 'ottu__sdk-block-apple-button',
  blockGoogleButton: 'ottu__sdk-block-google-button',
  blockStcButton: 'ottu__sdk-block-stc-button',
  blockUrpayButton: 'ottu__sdk-block-urpay-button',
  blockSamsungButton: 'ottu__sdk-block-samsung-button',
  modal: 'ottu__sdk-modal',
  modalYesBtn: 'ottu__sdk-yes-btn',
  modalNoBtn: 'ottu__sdk-no-btn',
  saveCardDeleteButton: 'ottu__sdk-del-btn',
  modalOverlay: 'ottu__sdk-overlay',
  blockIframeLoader: 'ottu__sdk-iframe-loader',
  applePayButton: 'ckoApplePayButton',
  googlePayButton: 'ckoGooglePayButton',
  stcPayButton: 'ckoStcPayButton',
  urPayButton: 'ckoUrPayButton',
  amountTitle: 'ottu__sdk-amount-title',
  feesBlock: 'ottu__sdk-fees-block',
  fees: 'ottu__sdk-fees',
  feesLabel: 'ottu__sdk-fee-label',
  feesCurrency: 'ottu__sdk-fees-currency',
  totalBlock: 'ottu__sdk-total-block',
  total: 'ottu__sdk-total1',
  totalCurrency: 'ottu__sdk-total-currency',
  applePayUnavailableText: 'applepay-unavailable-text',
  popupModal: 'ottu__sdk-popup-modal',
  mobilePayModal: 'ottu__sdk-payment-modal',
  mobilePayCheckbox: 'ottu__sdk-mobilePay-save',
  mobilePaySaveCard: 'ottu__sdk-mobile-save-card',
  mobilePayPhoneNumberBlock: 'mobilePay-mobile-Number-Block',
  mobilePayPhoneNumber: 'mobilePay-mobile-Number',
  mobilePayOTPBlock: 'mobilePay-OTP-block',
  mobilePayOTP: 'mobilePayOTP',
  mobilePayOtpSubmitBtn: 'ottu__sdk-otp-submit-btn',
  mobilePaySubmitBtn: 'ottu__sdk-mobile-submit-btn',
  mobilePayResendOtpBtn: 'ottu__sdk-otp-resend-btn',
  disabled: 'ottu__sdk-cursor-disabled'
};

exports.cardsSvg = {
  VISA: process.env.SERVER_URL + '/v3/img/VISA.svg',
  MASTERCARD: process.env.SERVER_URL + '/v3/img/MASTERCARD.svg',
  AMEX: process.env.SERVER_URL + '/v3/img/AMEX.svg',
  DISCOVER: process.env.SERVER_URL + '/v3/img/DISCOVER.svg',
  JCB: process.env.SERVER_URL + '/v3/img/JCB.svg',
  DINERS: process.env.SERVER_URL + '/v3/img/DINERS.svg',
  CUP: process.env.SERVER_URL + '/v3/img/CUP.svg',
  MAESTRO: process.env.SERVER_URL + '/v3/img/MAESTRO.svg',
  stc_pay: process.env.SERVER_URL + '/v3/img/STCPAY.svg',
  urpay: process.env.SERVER_URL + '/v3/img/URPAY.svg',
  checkoutcom: process.env.SERVER_URL + '/v3/img/CHECKOUTCOM.svg',
};

exports.logoSvg = {
  cvv: '<svg id="Group_1354" class="hide-tag-display" data-name="Group 1354" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_1041" data-name="Path 1041" d="M0,0H24V24H0Z" fill="none"></path><rect id="Rectangle_82" data-name="Rectangle 82" width="18" height="14" rx="3" transform="translate(3.378 4.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><line id="Line_54" data-name="Line 54" x2="18" transform="translate(3.378 9.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_55" data-name="Line 55" x2="0.01" transform="translate(7 15)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_56" data-name="Line 56" x2="2" transform="translate(11.378 14.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line></svg>',
  cardNumber:
    '<svg id="Group_1356" data-name="Group 1356" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_1034" data-name="Path 1034" d="M0,0H24V24H0Z" fill="none"></path><rect id="Rectangle_12" data-name="Rectangle 12" width="19.855" height="16.438" rx="3" transform="translate(2.443 3.781)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.363" cy="1.891" rx="2.363" ry="1.891" transform="translate(7.299 8.087)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></ellipse><line id="Line_49" data-name="Line 49" x2="1.891" transform="translate(15.817 8)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_50" data-name="Line 50" x2="1.891" transform="translate(15.817 12)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_51" data-name="Line 51" x2="9.453" transform="translate(7.733 16)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line></svg>',
  deleteFilled:
    '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"> <g clip-path="url(#clip0_106_2044)"> <path d="M20 40C31 40 40 31 40 20C40 9 31 0 20 0C9 0 0 9 0 20C0 31 9 40 20 40Z" fill="#F0252B" fill-opacity="0.08"/> <path d="M29 13.98C25.67 13.65 22.32 13.48 18.98 13.48C16.9962 13.4802 15.0137 13.5803 13.04 13.78L11 13.98" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.5 12.97L16.72 11.66C16.88 10.71 17 10 18.69 10H21.31C23 10 23.13 10.75 23.28 11.67L23.5 12.97" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M26.8499 17.1399L26.1999 27.2099C26.0899 28.7799 25.9999 29.9999 23.2099 29.9999H16.7899C13.9999 29.9999 13.9099 28.7799 13.7999 27.2099L13.1499 17.1399" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18.3301 24.5H21.6601" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M17.5 20.5H22.5" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </g> <defs> <clipPath id="clip0_106_2044"> <rect width="40" height="40" fill="white"/> </clipPath> </defs> </svg>',
  delete:
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C8.99622 5.48017 7.01371 5.5803 5.04 5.77998L3 5.97998" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M18.8504 9.14001L18.2004 19.21C18.0904 20.78 18.0004 22 15.2104 22H8.79039C6.00039 22 5.91039 20.78 5.80039 19.21L5.15039 9.14001" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10.3301 16.5H13.6601" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.5 12.5H14.5" stroke="#F0252B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
  modalX:
    '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="inherit"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2833 2.62605C13.6738 2.23553 13.6738 1.60236 13.2833 1.21184C12.8928 0.821314 12.2596 0.821314 11.8691 1.21184L7.00001 6.0809L2.13094 1.21184C1.74042 0.821314 1.10725 0.821314 0.716729 1.21184C0.326204 1.60236 0.326204 2.23553 0.716729 2.62605L5.58579 7.49512L0.292924 12.788C-0.0976003 13.1785 -0.0976007 13.8117 0.292924 14.2022C0.683448 14.5927 1.31661 14.5927 1.70714 14.2022L7.00001 8.90933L12.2929 14.2022C12.6834 14.5927 13.3166 14.5927 13.7071 14.2022C14.0976 13.8117 14.0976 13.1785 13.7071 12.788L8.41422 7.49512L13.2833 2.62605Z" fill="inherit"/> </svg>',
  back: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none"> <path d="M8.5 1.5L1 9L8.5 16.5" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
  loading:
    '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 50 50" xml:space="preserve"><path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"> <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform> </path> </svg>',
  greyloader:
    '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 50 50" xml:space="preserve"><path fill="#707070" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"> <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform> </path> </svg>',
  failed:
    '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none"> <path d="M19.6667 37.1667C28.8333 37.1667 36.3333 29.6667 36.3333 20.5C36.3333 11.3333 28.8333 3.83334 19.6667 3.83334C10.5 3.83334 3 11.3333 3 20.5C3 29.6667 10.5 37.1667 19.6667 37.1667Z" fill="#F0252B"/> <path d="M19.5 25.7727C18.6716 25.7727 18 26.3832 18 27.1364C18 27.8895 18.6716 28.5 19.5 28.5C20.3284 28.5 21 27.8895 21 27.1364C21 26.3832 20.3284 25.7727 19.5 25.7727ZM19.5 23.8636C20.1633 23.8636 20.7 23.3752 20.7 22.7727V14.5909C20.7 13.9885 20.1633 13.5 19.5 13.5C18.8367 13.5 18.3 13.9885 18.3 14.5909V22.7727C18.3 23.3752 18.8367 23.8636 19.5 23.8636Z" fill="white"/> </svg>',
  success:
    '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"> <path d="M19.9997 36.6666C29.1663 36.6666 36.6663 29.1666 36.6663 20C36.6663 10.8333 29.1663 3.33331 19.9997 3.33331C10.833 3.33331 3.33301 10.8333 3.33301 20C3.33301 29.1666 10.833 36.6666 19.9997 36.6666Z" fill="#0AC539"/> <path d="M12.917 20L17.6337 24.7167L27.0837 15.2833" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
  loader:
    '<svg id="dots" width="140px" height="35px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="dots" sketch:type="MSArtboardGroup" fill="#D9D9D9"> <circle id="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="7"></circle> <circle id="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="10"></circle> <circle id="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="13"></circle> </g> </g> </svg>',
  whiteLoader:
    '<svg id="dots" width="132px" height="35px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="dots" sketch:type="MSArtboardGroup" fill="#fff"> <circle id="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="7"></circle> <circle id="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="10"></circle> <circle id="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="13"></circle> </g> </g> </svg>',
};
