const {
  getFailedModalHtml,
  getIframeLoader,
  checkResponseStatus3ds,
  checkResponseStatusSuccess,
  checkResponseStatusCanceled,
  checkResponseStatusError,
  checkResponseStatusFailed,
  setMethod,
  resetAllSavedCards,
  getApplePayHtml,
  checkFormsofPayments,
  resetSelectedCheckboxes,
  phoneNumberSetSpace,
  ccvValidation,
  getFeesHtml,
  getSaveCardCheckbox,
  getWalletButtonsHtml,
  calculateSubTotal,
  getMobilePayModal,
  getFormOfPayment,
  getMobilePayOTPModal,
  getRejectModalHtml,
  validateMobilePayFields,
} = require('./util');

const { $t, $payButtonText } = require('./translate');
const {
  redirectPayFetch,
  tokenPayFetch,
  deleteTokenFetch,
  sendMobilePayOtp,
  submitMobilePayOTP,
  fetchInstallmentOptions,
  cancelPayFetch,
} = require('./http');
const { mainConfig, elements, method } = require('./variables');

class Selector {
  static setSelector(selector) {
    this.documentCheckout = selector;
  }

  static setCurrentScript(currScript) {
    this.curScriptElement = currScript;
  }

  constructor() {
    this.disableFileds = false;
  }

  static escKeyListener = null;

  static changeTotal(
    fee,
    currencyCode,
    currencyCodedDefault,
    amount,
    description
  ) {
    const payButton = this.documentCheckout.querySelector(`#${elements.payButton}`);
    if (payButton) {
      payButton.querySelector('span').innerHTML =
      $payButtonText() + `  (${amount} ${currencyCode || currencyCodedDefault})`;
    }

    if (fee > 0) {
      this.documentCheckout.querySelector(`.${elements.amount}`).innerHTML =
        calculateSubTotal(amount, fee);

      this.documentCheckout.querySelector(`.${elements.currency}`).innerHTML =
        currencyCode;

      this.documentCheckout.querySelector(`#${elements.feesBlock}`).innerHTML =
        getFeesHtml();

      this.documentCheckout
        .querySelectorAll(`.${elements.feesLabel}`)
        .forEach((element) => {
          if (description) {
            element.innerHTML = description;
          } else {
            element.innerHTML = $t('Applied Fees');
          }
        });

      this.documentCheckout.querySelector(
        `.${elements.fees}`
      ).innerHTML = `+${fee}`;

      this.documentCheckout.querySelector(
        `.${elements.feesCurrency}`
      ).innerHTML = currencyCode || currencyCodedDefault;
    } else {
      this.documentCheckout
        .querySelectorAll(`.${elements.amount}`)
        .forEach((element) => {
          element.innerHTML = amount;
        });
      this.documentCheckout
        .querySelectorAll(`.${elements.currency}`)
        .forEach((element) => {
          element.innerHTML = currencyCode;
        });
      this.documentCheckout.querySelector(`#${elements.feesBlock}`).innerHTML =
        '';
    }
  }

  static renderOnlyApplePay() {
    this.documentCheckout
      .querySelectorAll('.card-only-display')
      .forEach((element) => {
        element.style.display = 'none';
      });
    this.documentCheckout.querySelector('.ottu__sdk-main').style.padding =
      '0px';
  }

  static disableEnableFileds(disable, type) {
    const radios = this.documentCheckout.querySelectorAll('input');
    for (let index = 0; index < radios.length; index++) {
      const element = radios[index];
      element.disabled = disable;
    }

    const buttons = this.documentCheckout.querySelectorAll('button');
    for (let index = 0; index < buttons.length; index++) {
      const button = buttons[index];
      button.disabled = disable;
    }

    const popupButtons =
      this.documentCheckout.querySelectorAll('.popupButtons');
    if (popupButtons) {
      popupButtons.forEach((button) => {
        disable
          ? button.classList.add('loading')
          : button.classList.remove('loading');
      });
    }

    if (type == 'cancel') { 
      const canceButton =
      this.documentCheckout.querySelectorAll('.cancel-button');
      if (canceButton) {
        canceButton.forEach((button) => {
          disable
          ? button.classList.add('loading')
          : button.classList.remove('loading');
        });
      }
    }

    this.disableFileds = disable;
    const payButton = this.documentCheckout.querySelector(
      '#' + elements.payButton
    );
    if (payButton && !type) {
      if (disable) {
        payButton.classList.add('loading');
      } else {
        payButton.classList.remove('loading');
      }
    }
  }

  static payButtonClick() {
    if (!this.disableFileds) {
      if (method.type == 'token') {
        Selector.callBackPayment().then(() => {
          Selector._tokenPay();
        });
      } else if (method.type == 'ottu_pg') {
        Selector._ottuPGPay();
      } else if (method.type == 'redirect' || method.type == 'tamara' || method.type == 'tabby') {
        Selector._redirectPay();
      }
    }
  }

  static cancelButtonClick() {
    if (!this.disableFileds) {
      Selector.showCancelModal(getRejectModalHtml())
    }
  }

  static selectePayMethod(_this) {
    if (this.disableFileds) {
      return;
    }

    const name = _this.attributes.name.value;
    const type = _this.attributes['data-type'].value;
    const code = _this.attributes[name].value;
    const submitUrl = _this.attributes.submit_url
      ? _this.attributes.submit_url.value
      : '';
    const id = _this.attributes['id'] ? _this.attributes['id'].value : '';
    const ccv_required = _this.attributes['data-ccv_required']
      ? _this.attributes['data-ccv_required'].value === 'true'
      : false;
    const fee = _this.attributes['data-fee']
      ? _this.attributes['data-fee'].value
      : '';
    const currencyCode = _this.attributes['data-currency_code']
      ? _this.attributes['data-currency_code'].value
      : '';
    const currencyCodedDefault = _this.attributes['data-currency_code_default']
      ? _this.attributes['data-currency_code_default'].value
      : '';
    const amount = _this.attributes['data-amount']
      ? _this.attributes['data-amount'].value
      : '';
    const description = _this.attributes['data-description']
      ? _this.attributes['data-description'].value
      : '';
    const gateway = _this.attributes['data-gateway']
      ? _this.attributes['data-gateway'].value
      : '';
    const cancel_url =
      _this.attributes['cancel_url'] &&
      _this.attributes['cancel_url'].value != 'undefined'
        ? _this.attributes['cancel_url'].value
        : '';
    const payment_url =
      _this.attributes['payment_url'] &&
      _this.attributes['payment_url'].value != 'undefined'
        ? _this.attributes['payment_url'].value
        : '';
    let canSaveCard =
      _this.attributes['can-save-card'] &&
      _this.attributes['can-save-card'].value != 'undefined'
        ? _this.attributes['can-save-card'].value
        : false;
    const redirectUrl = _this.attributes['redirect_url']
      ? _this.attributes['redirect_url'].value
      : '';

    Selector.changeTotal(
      fee,
      currencyCode,
      currencyCodedDefault,
      amount,
      description
    );
    setMethod(
      name,
      code,
      submitUrl,
      cancel_url,
      redirectUrl,
      payment_url,
      id,
      ccv_required,
      canSaveCard,
      amount,
      currencyCode,
      gateway
    );

    this.documentCheckout
      .querySelectorAll('.' + elements.paymentMethod)
      .forEach((element) => {
        element.classList.remove('ottu__sdk-border-green');
      });

    resetAllSavedCards(
      this.documentCheckout.querySelectorAll('.ottu__sdk-input-ccv')
    );

    resetSelectedCheckboxes(
      this.documentCheckout.querySelectorAll('.' + elements.accordionItemRadio),
      _this
    );

    this.documentCheckout
      .querySelectorAll('.ottu__sdk-radio-div')
      .forEach((element) => {
        if (element.querySelector('input').checked) {
          element.querySelector(`.${method.id}`)
            ? element.querySelector(`.${method.id}`).classList.add('d-flex')
            : '';
        } else {
          element.querySelector('.d-flex')
            ? element.querySelector('.d-flex').classList.remove('d-flex')
            : '';
        }
      });

    if (name == 'ottu_pg' || name == 'stc_pay' || name == 'urpay') {
      canSaveCard = false;
    }

    this.documentCheckout.querySelector(
      `#${elements.cardSaveBlock}`
    ).innerHTML = getSaveCardCheckbox(canSaveCard);

    if (type === 'method') {
      _this.classList.toggle('ottu__sdk-border-green');
    }
  }

  static _redirectPay() {
    if (checkFormsofPayments('redirect')) {
      Selector.disableEnableFileds(true);

      const cardSave = this.documentCheckout.querySelector(
        '#' + elements.cardSaveCheckbox
      )
        ? this.documentCheckout.querySelector('#' + elements.cardSaveCheckbox)
          .checked
        : false;
      const submit_url = window.redirectSubmitUrl
        ? window.redirectSubmitUrl
        : method.submit_url;

      redirectPayFetch(submit_url, mainConfig.apiKey, method.code, cardSave)
        .then((response) => {
          Selector.successResponse(response, 'redirect');
        })
        .catch((err) => {
          Selector.disableEnableFileds(false);
          if (err.status >= 500) {
            err = {
              status: 'error',
              message:
                'Oops, something went wrong. Refresh the page and try again.',
            };
            Selector.callBackError(err);
          } else {
            Selector.errorResponse(err);
          }
        });
    }
  }

  static _mobilePay(type) {
    Selector.disableEnableFileds(true, type);
    mainConfig.customer_phone = mainConfig.customer_phone.replace(
      /[^0-9]+/g,
      ''
    );
    Selector.showMobilePaymentModal(
      getMobilePayModal(
        mainConfig.customer_phone,
        method.can_save_card,
        false,
        type
      ),
      'otp',
      false,
      type
    );
  }

  static showCancelModal(html) {
    const modal = Selector.documentCheckout.querySelector(
      '#' + elements.modal
    );
    const modalOverlay = Selector.documentCheckout.querySelector(
      '#' + elements.modalOverlay
    );
    modal.innerHTML = html;
    modal.classList.remove('d-none');
    modal.classList.add('ottu__sdk-active');
    modalOverlay.classList.add('ottu__sdk-active');

    const yesBtn = modal.querySelector('#' + elements.modalYesBtn);
    const noBtn = modal.querySelector('#' + elements.modalNoBtn);

    yesBtn.addEventListener('click', () => {
      Selector._cancelPay();
      Selector.hideModal();
    })

    noBtn.addEventListener('click', () => {
      Selector.hideModal();
    })
  }

  static async _cancelPay() {
    Selector.disableEnableFileds(true, 'cancel');
    cancelPayFetch(mainConfig.cancel_url, mainConfig.apiKey)
    .then((response) => {
      Selector.disableEnableFileds(false);
      Selector.callBackCancel(response);
    })
    .catch((err) => {
      Selector.disableEnableFileds(false, 'cancel');
      if (err.status >= 500) {
        err = {
          status: 'error',
          message:
            'Oops, something went wrong. Refresh the page and try again.',
        };
        Selector.callBackError(err);
      } else {
        Selector.errorResponse(err);
      }
    });
  }

  static async _ottuPGPay() {
    Selector.disableEnableFileds(true);
    let redirectUrl = method.redirect_url;
    if (mainConfig.lang === 'ar') {
      redirectUrl += `?setLang=${mainConfig.lang}`;
    }
  
    try {
      await Selector.callBackPayment({ redirect_url: redirectUrl || '' })
    } catch (error) {
      Selector.disableEnableFileds(false);
      Selector.errorResponse(error);
    }
  }

  static _tokenPay() {
    Selector.disableEnableFileds(true);
    let cardCCV;
    if (method.ccv_required) {
      this.documentCheckout
        .querySelectorAll('.ottu__sdk-radio-div')
        .forEach((element) => {
          if (element.querySelector('input').checked) {
            cardCCV = element
              .querySelector('label')
              .querySelector('input').value;
          }
        });

      const validation = ccvValidation(cardCCV);
      if (validation.error) {
        this.documentCheckout
          .querySelector(`.${method.id}`)
          .classList.add('ottu__sdk-invalid-input');
        this.documentCheckout
          .querySelector(`.${method.id}`)
          .querySelector('i').innerHTML =
          validation.errors['tokenCardCcvBlock'];
        Selector.disableEnableFileds(false);
      } else {
        tokenPayFetch(
          method.submit_url,
          mainConfig.apiKey,
          mainConfig.merchant_id,
          mainConfig.session_id,
          method.code,
          cardCCV
        )
          .then((response) => {
            Selector.disableEnableFileds(false);
            Selector.successResponse(response, 'token');
          })
          .catch((err) => {
            Selector.disableEnableFileds(false);
            if (err.status >= 500) {
              err = {
                status: 'error',
                message:
                  'Oops, something went wrong. Refresh the page and try again.',
              };
              Selector.callBackError(err);
            } else {
              Selector.errorResponse(err);
            }
          });
      }
    } else {
      tokenPayFetch(
        method.submit_url,
        mainConfig.apiKey,
        mainConfig.merchant_id,
        mainConfig.session_id,
        method.code
      )
        .then((response) => {
          Selector.disableEnableFileds(false);
          Selector.successResponse(response, 'token');
        })
        .catch((err) => {
          Selector.disableEnableFileds(false);
          if (err.status >= 500) {
            err = {
              status: 'error',
              message:
                'Oops, something went wrong. Refresh the page and try again.',
            };
            Selector.callBackError(err);
          } else {
            Selector.errorResponse(err);
          }
        });
    }
  }

  static successResponse(response, type, mobilePay) {
    if (mobilePay) {
      var modal = Selector.documentCheckout.querySelector(
        '#' + elements.mobilePayModal
      );
      var otpBlock = modal.querySelector('#' + elements.mobilePayOTPBlock);
      Selector.disableEnableFileds(false, mobilePay);
    } else {
      Selector.disableEnableFileds(false);
    }
    if (checkResponseStatus3ds(response)) {
      if (type === 'token' && method.pg === 'checkoutcom') {
        Selector.callBackPayment(response);
      } else {
        Selector.generate3DSPopUp(response);
      }
    } else if (checkResponseStatusSuccess(response)) {
      if (type === 'redirect') {
        Selector.callBackPayment({ redirect_url: response.redirect_url || '' });
      } else {
        if (mobilePay) {
          Selector.hideModal(mobilePay);
        }
        Selector.callBackSuccess(response);
      }
    } else if (
      checkResponseStatusCanceled(response) ||
      checkResponseStatusFailed(response)
    ) {
      if (mobilePay) {
        Selector.showErrorMessage(modal, response, otpBlock, mobilePay);
      }
      Selector.callBackCancel(response);
    } else if (checkResponseStatusError(response)) {
      if (mobilePay) {
        Selector.showErrorMessage(modal, response, otpBlock, mobilePay);
      }
      Selector.callBackError(response);
    } else {
      Selector.callBackError({
        status: 'error',
        message: 'incorrect response',
      });
    }
  }

  static async getinstallmentsOptions(validationUrl, code) {
    try {
      return await fetchInstallmentOptions(validationUrl, mainConfig.apiKey, code)
    } catch (error) {
      Selector.errorResponse(error);
      return null;
    }
  }

  static showHide3ds(show) {
    const sdk = this.documentCheckout.querySelector('#' + elements.sdkChild);
    const threeDS = this.documentCheckout.querySelector('#' + elements.threeDS);
    if (show) {
      sdk.classList.add('d-none');
      threeDS.classList.remove('d-none');
    } else {
      threeDS.innerHTML = '';
      threeDS.classList.add('d-none');
      sdk.classList.remove('d-none');
    }
  }

  static generate3DSPopUp(response) {
    const wsUrlPattern = new RegExp(/(\S+\.)?ottu\.(dev|net)/);
    if (!wsUrlPattern.test(response.ws_url)) {
      Selector.callBackError({
        status: 'error',
        message: 'Invalid WS_URL',
      });
    } else {
      const _this = this;
      const sdk = _this.documentCheckout.querySelector('#' + elements.sdkChild);
      const threeDS = _this.documentCheckout.querySelector(
        '#' + elements.threeDS
      );
      if (sdk && threeDS) {
        Selector.showHide3ds(true, sdk, threeDS);
        threeDS.innerHTML = `${getIframeLoader()}<div id="dsIframe" class="ottu__sdk-ds-block 3ds-container hide">${
          response.html
        }</div>`;
        const formElement = threeDS.getElementsByTagName('form');

        let formId = 'redirectTo3ds1Form';
        if (formElement.length) {
          formId = formElement[0].id;
        }

        var threeDsform = this.documentCheckout.getElementById(formId);
        if (threeDsform) {
          threeDsform.submit();
          if (threeDsform.parentNode !== null) {
            threeDsform.parentNode.removeChild(threeDsform);
          }
        }

        let count = 0;
        const threeDSIframe = threeDS.querySelector('iframe');
        threeDSIframe.onload = function () {
          if (count == 0) {
            _this.documentCheckout
              .querySelector('#' + 'dsIframe')
              .classList.remove('hide');
            _this.documentCheckout
              .querySelector('#' + elements.blockIframeLoader)
              .classList.add('hide');
          } else if (count == 1) {
            _this.documentCheckout
              .querySelector('#' + 'dsIframe')
              .classList.add('hide');
            _this.documentCheckout
              .querySelector('#' + elements.blockIframeLoader)
              .classList.remove('hide');
          }
          count++;
        };

        const socket = new WebSocket(response.ws_url);
        socket.addEventListener('message', function (event) {
          let res = JSON.parse(event.data);
          if (checkResponseStatusSuccess(res)) {
            Selector.callBackSuccess(res);
          } else if (checkResponseStatusCanceled(res)) {
            Selector.callBackCancel(res);
            Selector.showHide3ds(false);
          } else if (checkResponseStatusError(res)) {
            Selector.callBackError(res);
            Selector.showHide3ds(false);
          } else {
            Selector.callBackError({
              status: 'error',
              message: 'ws incorrect response ',
            });
            Selector.showHide3ds(false);
          }
          socket.close();
          Selector.disableEnableFileds(false);
        });
        socket.addEventListener('open', function () {
          socket.send(
            `{"client_type": "sdk", "reference_number": "${response.reference_number}", "merchant_id": "${mainConfig.merchant_id}"}`
          );
        });
        socket.addEventListener('close', function () {});
      }
    }
  }

  static errorResponse(err) {
    if (typeof err === 'object' && typeof err.then === 'function') {
      err.then((error) => {
        if (error.detail) {
          let buttonText = error.operation === 'cancel' ? 'OK' : null
          Selector.showModal(
            getFailedModalHtml(
              error.detail || 'Something Went Wrong.',
              $t('Error'),
              buttonText
            ),
            null,
            'info'
          );
        } else if (
          Object.keys(error).length &&
          Array.isArray(error[Object.keys(error)[0]])
        ) {
          let htmlContent = '';
          for (let key in error) {
            error[key].forEach((value) => {
              htmlContent += '<p>' + value + '</p>';
            });
          }
          Selector.showModal(
            getFailedModalHtml(htmlContent || 'Something Went Wrong.', $t('Error')),
            null,
            'info'
          );
        } else {
          console.log(error);
        }
      });
    } else {
      Selector.callBackError({
        status: 'error',
        message: 'Server error responses status ' + err.message,
      });
    }
  }

  static callBackSuccess(response) {
    if (!response.redirect_url) response.redirect_url = window.location.href;

    if (Selector.checkCallback('data-success', 'successCallback')) {
      if (window[this.curScriptElement.dataset.success]) {
        try {
          window[this.curScriptElement.dataset.success](response);
        } catch (error) {
          console.log('Checkout SDK successCallback Error:', error);
        }
      }
    } else {
      console.warn('WARNING: Success callback is not configured');
    }
  }

  static callBackPayment(response) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (
        Selector.checkCallback('data-beforepayment', 'beforePayment') &&
        window[this.curScriptElement.dataset.beforepayment]
      ) {
        try {
          await window[this.curScriptElement.dataset.beforepayment](
            response || {}
          );
          if (response && response.redirect_url) {
            window.location.href = response.redirect_url;
          }
          resolve();
        } catch (error) {
          Selector.disableEnableFileds(false);
          console.log('Checkout SDK beforePayment Error:', error);
          reject(error);
        }
      } else {
        console.warn('WARNING: Before Payment Hook is not configured');
        if (response && response.redirect_url) {
          window.location.href = response.redirect_url;
        }
        resolve();
      }
    });
  }

  static callBackCancel(response) {
    if (
      Selector.checkCallback('data-cancel', 'cancelCallback') &&
      window[this.curScriptElement.dataset.cancel]
    ) {
      try {
        window[this.curScriptElement.dataset.cancel](response);
      } catch (error) {
        console.log('Checkout SDK cancelCallback Error:', error);
      }
    } else {
      console.warn('WARNING: Cancel callback is not configured');
    }
  }

  static callBackError(response) {
    if (Selector.checkCallback('data-error', 'errorCallback')) {
      if (window[this.curScriptElement.dataset.error]) {
        try {
          window[this.curScriptElement.dataset.error](response);
        } catch (error) {
          console.log('Checkout SDK errorCallback Error:', error);
        }
      }
    } else {
      console.warn('WARNING: Error callback is not configured');
    }
  }

  static checkCallback(data) {
    const scriptDocument = document.querySelector(
      `script[src='${process.env.SERVER_URL}/v3/checkout.min.js']`
    );

    if (!(scriptDocument && scriptDocument.getAttribute(data))) {
      return false;
    }

    return true;
  }

  static showModal(html, response, type) {
    let modal;
    if (type == 'info') {
      modal = Selector.documentCheckout.querySelector(
        '#' + elements.popupModal
      );
    } else {
      modal = Selector.documentCheckout.querySelector('#' + elements.modal);
    }
    const modalOverlay = Selector.documentCheckout.querySelector(
      '#' + elements.modalOverlay
    );
    modal.innerHTML = html;
    modal.classList.remove('d-none');
    modal.classList.add('ottu__sdk-active');
    modalOverlay.classList.add('ottu__sdk-active');
    modal.querySelectorAll('.close-button').forEach((closeBtns) => {
      closeBtns.addEventListener(
        'click',
        function () {
          if (response && response.status == 'canceled') {
            Selector.callBackCancel(response);
          } else if (response && response.status == 'error') {
            Selector.callBackError(response);
          }
          if (type === 'info') {
            Selector.hideModal(type);
          } else {
            Selector.hideModal();
          }
        },
        true
      );
    });
  }

  static handleOtpResend(modal, otpBlock, saveCard, type) {
    let resendOtpBtn = modal.querySelector(
      '#' + elements.mobilePayResendOtpBtn
    );
    let payBtn = modal.querySelector('#' + elements.mobilePayOtpSubmitBtn);
    let startTime = mainConfig.mobilePayOtpCooldownTime;
    let timer;

    if (resendOtpBtn) {
      timer = setInterval(() => {
        resendOtpBtn.querySelector('span').innerHTML =
          $t('Resend OTP') + '(' + --startTime + $t('Sec') + ')';
      }, 1000);
      setTimeout(function () {
        clearInterval(timer);
        resendOtpBtn.querySelector('span').innerHTML = $t('Resend OTP');
        resendOtpBtn.classList.remove('ottu__sdk-disabled');
        resendOtpBtn.disabled = false;

        resendOtpBtn.addEventListener('click', () => {
          resendOtpBtn.classList.add('loading');
          payBtn.disabled = true;
          Selector.disableEnableFileds(true, type);
          sendMobilePayOtp(
            method.submit_url,
            mainConfig.apiKey,
            mainConfig.session_id,
            method.code,
            mainConfig.customer_phone,
            saveCard
          )
            .then(() => {
              resendOtpBtn.classList.remove('loading');
              payBtn.disabled = false;
              Selector.disableEnableFileds(false);
              Selector.showMobilePaymentModal(
                getMobilePayOTPModal(),
                'pay',
                saveCard,
                type
              );
            })
            .catch((err) => {
              resendOtpBtn.classList.remove('loading');
              Selector.showErrorMessage(modal, err, otpBlock, type);
            });
        });
      }, startTime * 1000);
    }
  }

  static closeMobileModal(type) {
    Selector.hideModal(type);
    Selector.unselectMethod();
    Selector.disableEnableFileds(false, type);
  }

  static handleMobileModalEscKey(type, action) {
    if (event.key === 'Escape' && !Selector.disableFileds) {
      Selector.closeMobileModal(type, action);
      if (action === 'pay') {
        Selector.callBackError({
          form_of_payment: getFormOfPayment(type),
          message: 'Payment Cancelled By User',
        });
      }
    } else {
      Selector.addEscKeyListeners(Selector.escKeyListener);
    }
  }

  static removeEscKeyListeners(handleEscKey) {
    document.removeEventListener('keydown', handleEscKey);
  }

  static addEscKeyListeners(handleEscKey) {
    document.addEventListener('keydown', handleEscKey, { once: true });
  }

  static showMobilePaymentModal(html, action, saveCardValue, type) {
    let saveCard = saveCardValue;
    const modal = Selector.documentCheckout.querySelector(
      '#' + elements.mobilePayModal
    );
    const modalOverlay = Selector.documentCheckout.querySelector(
      '#' + elements.modalOverlay
    );
    modal.innerHTML = html;
    modal.classList.remove('d-none');
    modal.classList.add('ottu__sdk-active');
    modalOverlay.classList.add('ottu__sdk-active');
    Selector.disableEnableFileds(false);
    Selector.escKeyListener = Selector.handleMobileModalEscKey.bind(
      null,
      type,
      action
    );

    const closeButton = modal.querySelector('.close-button');
    closeButton.addEventListener('click', () => {
      Selector.removeEscKeyListeners(Selector.escKeyListener);
      Selector.closeMobileModal(type);
      if (action === 'pay') {
        Selector.callBackError({
          form_of_payment: getFormOfPayment(type),
          message: 'Payment Cancelled By User',
        });
      }
    });

    if (action == 'otp') {
      let mobileNoBlock = modal.querySelector(
        '#' + elements.mobilePayPhoneNumberBlock
      );
      let sendBtn = modal.querySelector('#' + elements.mobilePaySubmitBtn);
      Selector.addEscKeyListeners(Selector.escKeyListener);

      mobileNoBlock
        .querySelector('input')
        .addEventListener('input', (e) => {
          mobileNoBlock.classList.remove('ottu__sdk-invalid-input');
          modal.querySelector('i').innerHTML = '';
          e.target.value = e.target.value.replace(/[^0-9]+/g, '').slice(0, 12);
          e.target.value = phoneNumberSetSpace(e.target.value);
        });

      if (sendBtn) {
        sendBtn.addEventListener(
          'click',
          () => {
            Selector.callBackPayment()
              .then(() => {
                mainConfig.customer_phone = modal
                  .querySelector('#' + elements.mobilePayPhoneNumber)
                  .value.replace(/ /g, '');
                saveCard = modal.querySelector('#' + elements.mobilePaySaveCard)
                  ? modal.querySelector('#' + elements.mobilePaySaveCard)
                    .checked
                  : false;
                const valid = validateMobilePayFields(
                  mainConfig.customer_phone,
                  'phoneNo'
                );
                if (valid) {
                  Selector.disableEnableFileds(true, type);
                  sendMobilePayOtp(
                    method.submit_url,
                    mainConfig.apiKey,
                    mainConfig.session_id,
                    method.code,
                    mainConfig.customer_phone,
                    saveCard
                  )
                    .then(() => {
                      Selector.disableEnableFileds(false);
                      Selector.showMobilePaymentModal(
                        getMobilePayOTPModal(),
                        'pay',
                        saveCard,
                        type
                      );
                    })
                    .catch((err) => {
                      Selector.showErrorMessage(
                        modal,
                        err,
                        mobileNoBlock,
                        type
                      );
                    });
                } else {
                  const errMessage = {
                    message: 'Please enter a valid mobile number',
                  };
                  Selector.showErrorMessage(
                    modal,
                    errMessage,
                    mobileNoBlock,
                    type
                  );
                }
              })
              .catch(() => {
                Selector.closeMobileModal(type);
              });
          },
          true
        );
      }
    } else if (action == 'pay') {
      let otpBlock = modal.querySelector('#' + elements.mobilePayOTPBlock);
      let payBtn = modal.querySelector('#' + elements.mobilePayOtpSubmitBtn);

      Selector.addEscKeyListeners(Selector.escKeyListener);
      Selector.handleOtpResend(modal, otpBlock, saveCardValue, type);

      otpBlock.querySelector('input').addEventListener('input', function (e) {
        otpBlock.classList.remove('ottu__sdk-invalid-input');
        e.target.value = e.target.value.replace(/[^0-9]+/g, '');
      });

      modal
        .querySelector('.ottu__sdk-back-button')
        .addEventListener('click', () => {
          Selector.removeEscKeyListeners(Selector.escKeyListener);
          Selector.showMobilePaymentModal(
            getMobilePayModal(
              mainConfig.customer_phone,
              method.can_save_card,
              saveCard
            ),
            'otp',
            saveCard,
            type
          );
          Selector.callBackError({
            form_of_payment: getFormOfPayment(type),
            message: 'Payment Cancelled By User',
          });
        });

      if (payBtn) {
        payBtn.addEventListener(
          'click',
          () => {
            let otpValue = modal
              .querySelector('#' + elements.mobilePayOTP)
              .value.replace(/ /g, '');
            if (otpValue) {
              payBtn.classList.add('loading');
              Selector.disableEnableFileds(true, type);
              submitMobilePayOTP(
                method.payment_url,
                mainConfig.apiKey,
                mainConfig.session_id,
                otpValue
              )
                .then((response) => {
                  payBtn.classList.remove('loading');
                  Selector.successResponse(response, '', type);
                })
                .catch((err) => {
                  payBtn.classList.remove('loading');
                  Selector.showErrorMessage(modal, err, otpBlock, type);
                });
            } else {
              Selector.showErrorMessage(
                modal,
                { message: 'Please enter OTP' },
                otpBlock,
                type
              );
            }
          },
          true
        );
      }
    }
  }

  static hideModal(type) {
    let modal;
    if (type === 'stcPay' || type === 'urPay') {
      modal = Selector.documentCheckout.querySelector(
        '#' + elements.mobilePayModal
      );
    } else if (type === 'info') {
      modal = Selector.documentCheckout.querySelector(
        '#' + elements.popupModal
      );
    } else {
      modal = Selector.documentCheckout.querySelector('#' + elements.modal);
    }
    const modalOverlay = Selector.documentCheckout.querySelector(
      '#' + elements.modalOverlay
    );
    modal.classList.remove('ottu__sdk-active');
    modal.classList.remove('d-none');
    modalOverlay.classList.remove('ottu__sdk-active');
    modal.innerHTML = '';
  }

  static pay({ token }) {
    setMethod('token', token, process.env.SUBMIT_URL);
    Selector._tokenPay();
  }

  static _deleteToken(url, radioDiv) {
    Selector.disableEnableFileds(true, 'token');
    deleteTokenFetch(url, mainConfig.apiKey)
      .then(() => {
        Selector.hideModal();
        Selector.unselectMethod();
        radioDiv.remove();
        let savedCards = this.documentCheckout.querySelectorAll(
          '.ottu__sdk-radio-div'
        );
        if (!savedCards.length) {
          this.documentCheckout
            .querySelector('#' + elements.savedCards)
            .classList.add('d-none');
        }
        Selector.disableEnableFileds(false);
      })
      .catch((err) => {
        Selector.disableEnableFileds(false);
        Selector.hideModal();
        Selector.errorResponse(err);
      });
  }

  static showAppleButton(applePay, isWalletActive) {
    if (applePay) {
      let block = this.documentCheckout.querySelector(
        `#${elements.blockAppleButton}`
      );
      block.classList.remove('d-none');
      block.innerHTML = getApplePayHtml(applePay, isWalletActive);
    }
  }

  static showGoogleButton(button) {
    if (button) {
      button.classList.add('google-pay-animation');
      let block = this.documentCheckout.querySelector(
        `#${elements.blockGoogleButton}`
      );
      block.classList.remove('d-none');
      block.innerHTML = '';
      block.appendChild(button);
    }
  }

  static showStcButton(stcPay) {
    let block = this.documentCheckout.querySelector(
      `#${elements.blockStcButton}`
    );
    block.innerHTML = getWalletButtonsHtml(stcPay);
    block.classList.remove('d-none');
  }

  static showUrPayButton(urPay) {
    let block = this.documentCheckout.querySelector(
      `#${elements.blockUrpayButton}`
    );
    block.innerHTML = getWalletButtonsHtml(urPay);
    block.classList.remove('d-none');
  }

  static showErrorMessage(modal, err, element, type) {
    Selector.addEscKeyListeners(Selector.escKeyListener);
    element.classList.add('ottu__sdk-invalid-input');
    this.disableEnableFileds(false, type);
    if (err.status >= 500) {
      modal.querySelector('i').innerHTML = 'Something Went Wrong';
    } else if (typeof err === 'object' && typeof err.then === 'function') {
      err.then((error) => {
        if (error.message) {
          modal.querySelector('i').innerHTML = error.message;
        }
      });
    } else if (err.message) {
      modal.querySelector('i').innerHTML = $t(err.message);
    }
  }

  static unselectMethod() {
    method.type = '';
    this.documentCheckout.querySelector(`#${elements.feesBlock}`).innerHTML =
      '';
    this.documentCheckout.querySelector(
      `#${elements.cardSaveBlock}`
    ).innerHTML = getSaveCardCheckbox(false);
  }

  static translateHeaders(lang) {
    this.documentCheckout.querySelector("#ottu-js-sdk").setAttribute("class", lang || 'en');
    this.documentCheckout.querySelector(".ottu__sdk-header").innerHTML = $t('Payment Details');
    this.documentCheckout.querySelector(".ottu__sdk-header2").innerHTML = $t('Pay With');
  }
  
  static showSamsungButton(button) {
    if (button) {
      button.classList.add("ottu__sdk-black-div")
      let block = this.documentCheckout.querySelector(
        `#${elements.blockSamsungButton}`
      );
      block.classList.remove('d-none');
      block.innerHTML = '';
      block.appendChild(button);
    }
  }
}

exports.Selector = Selector;
