const { mainConfig } = require('./variables');
const langs = {
  ar: {
    'Payment Details': 'تفاصيل الدفع',
    'Total Bill': 'إجمالي المبلغ',
    'Debit / Credit Card': 'بطاقة الصراف الآلي / بطاقة ائتمان',
    'Name On Card': 'الاسم على البطاقة',
    'Card Number': 'رقم البطاقة',
    'Click To Save Your Card': 'احفظ البطاقة',
    'You have incorrectly entered your expiration date': 'لقد أدخلت تاريخ انتهاء الصلاحية بشكل غير صحيح',
    'Saved Cards': 'البطاقات المحفوظة',
    'List of all cards saved': 'قائمة البطاقات المحفوظة',
    'Expires on': 'تنتهي في',
    'Expired on': 'انتهت في',
    'Expired': 'انتهت',
    'Click Confirm if you don\'t want this payment method to be displayed in your payment options': 'إذا كنت لا تريد عرض طريقة الدفع هذه في خيارات الدفع',
    'Delete': 'حذف',
    'Cancel': 'الغاء',
    'Payment Failed': 'فشل عملية الدفع',
    'OK': 'موافق',
    'Remove': 'حذف',
    'Expiry': 'تاريخ انتهاء الصلاحية',
    'CCV': 'رمز التحقق من البطاقة',
    'MM/YY': 'شهر/سنة',
    'Pay Now': 'ادفع الآن',
    'This field is required': 'حقل مطلوب',
    'Invalid Card Number': 'رقم البطاقة غير صحيح',
    'Payment Method': 'وسيلة دفع',
    'List of all gateways we support': 'قائمة بجميع بوابات الدفع التي ندعمها',
    'Fees': 'رسوم',
    'Total': 'الاجمالي',
    'Sub-Total': 'المجموع',
    'Apple Pay is unavailable. Please update your device, set up Wallet, add an active card, ensure the domain is verified by Apple, and refresh the page.':'الدفع عن طريق Apple Pay غير متاح. من فضلك  قم بتحديث جهازك. ومن ثم انشئ المحفظة واضف كارت  بنكي. وتأكد ان النطاق معتمد عبر ابل. واعد تحديث الصفحة',
    'Payment Methods': 'وسائل الدفع', 
    'Confirm Phone Number': 'رقم الهاتف المحمول',
    'Send OTP': ' الرجاء إدخال كلمة مرور لمرة واحدة ',
    'Enter OTP': ' الرجاء إدخال كلمة مرور لمرة واحدة ',
    'Pay': 'إدفع',
    'Please Enter a valid Phone Number': ' الرجاء إدخال رقم صحيح للهاتف المحمول ',
    'Something Went Wrong': 'حدث خطأ',
    'Mobile Number': 'رقم الهاتف المحمول',
    'OTP':'كلمة مرور لمرة واحدة ',
    'Resend OTP':' اعادة ارسال كلمة مرور لمرة واحدة ',
    'Sec': '  ثانية ',
    'Save STC Account':'حفظ البطاقة',
    'Please enter a valid mobile number':'الرجاء ادخال رقم هاتف صحيح',
    'Please enter OTP': 'من فضلك ادخل كلمة مرور لمرة واحدة ',
    'Please Enter OTP': 'من فضلك ادخل كلمة مرور لمرة واحدة ',
    'Enter Mobile Number': 'من فضلك ادخل رقم الهاتف المحمول',
    'Or Pay With': 'او ادفع بواسطة',
    'Pay With': 'ادفع بواسطة',
    'Amount': 'المبلغ' ,
    'For Faster And More Secure Checkout, Save Your Card Details': 'لدفع سريع وآمن , احفظ بطاقة الدفع',
    'Installments Payment option is Not available for you': 'خيار الدفع بالتقسيط غير متاح لك',
    'Pay in 4 interest-free payments': 'قسّمها على 4 دفعات بدون فوائد',
    'Reject': 'الغاء',
    'Are you sure you want to cancel the transaction?': 'هل انت متأكد من انك تريد الغاء العملية؟',
    'Yes': 'نعم',
    'No': 'لا',
    'Error': 'خطأ',
    'Try Again': 'حاول مرة اخرى',
  }
};

const $t = (text) => {
  if (!text) return '';
  text = String(text);
  if (langs[mainConfig.lang] && langs[mainConfig.lang][text]) {
    return langs[mainConfig.lang][text];
  } else {
    return text;
  }
};

const $payButtonText = () => {
  const fallbackTranslations = {
    'en': 'Pay',
    'ar': 'إدفع'
  };

  if (mainConfig.payButtonText) { 
    const lang = mainConfig.lang || 'en';
    return mainConfig.payButtonText[lang] || fallbackTranslations[lang];
  }
  
  return fallbackTranslations[mainConfig.lang || 'en'];
};

exports.$t = $t;
exports.$payButtonText = $payButtonText;