const {
  mainConfig,
  logoSvg,
  elements,
  cardsSvg,
  method,
} = require('./variables');
const { $t, $payButtonText } = require('./translate');

const totalAmountHtml = function () {
  const html = `
    <div class="ottu__sdk-flex amount-box ottu__sdk-align-center card-only-display ottu__sdk-amount-box-theme">
      <span class="${
        elements.amountTitle
      } ottu__sdk-total ottu__sdk-primary-text-theme ottu__sdk-amount-label-theme">${$t(
    'Amount'
  )}</span>
      <div class="ottu_sdk-amount-value">
        <span class="${
          elements.amount
        } ottu__sdk-amount ottu__sdk-primary-text-theme ottu__sdk-amount-theme"><span class="skeleton-loader"></span></span>
        <span class="${
          elements.currency
        } ottu__sdk-currency ottu__sdk-amount ottu__sdk-primary-text-theme ottu__sdk-amount-currency-theme"><span class="skeleton-loader"></span></span>
      </div>
    </div>
   `;
  return html;
};
exports.totalAmountHtml = totalAmountHtml;

const feesHtml = function () {
  const html = `
    <div class="ottu__sdk-flex wd-100">
    <span class="${elements.feesLabel} layout-flex-basis ottu__sdk-fee-label ottu__sdk-primary-text-theme ottu__sdk-fees-label-theme text-align--start">
      <span class="skeleton-loader"></span>
    </span>
    <div class="layout-flex-basis text-align--end">
      <span class="${elements.fees} ottu__sdk-primary-text-theme ottu__sdk-fees-theme"><span class="skeleton-loader"></span></span>
      <span class="${elements.feesCurrency} ottu__sdk-currency ottu__sdk-primary-text-theme ottu__sdk-fees-currency-theme"><span class="skeleton-loader"></span></span>
    </div>
    </div>
  `;
  return html;
};
exports.feesHtml = feesHtml;

const html = function (displayMode) {
  const html = `
    <div id="${elements.sdkChild}">
      <div class="ottu__sdk-container ottu__sdk-main ottu__sdk-position-relative ottu__sdk-main-theme ${
        displayMode == 'column' ? 'ottu__sdk-shrink' : ''
      }">
        <div class="layout flex-col" >
          <div class="layout-flex-basis card-only-display">
            <div class="ottu__sdk-header ottu__sdk-title-text-theme ottu__sdk-payment-details-heading-theme">${$t(
              'Payment Details'
            )}</div>
              <div id="${elements.totalBlock}" class="ottu__sdk-flex layout">
              <span class="skeleton-loader amount-box-loader">
              </div>
            </div>
          </div>
          <div class="ottu__sdk-flex wallet-buttons ottu__sdk-wallet-buttons-theme">
            <div id="${
              elements.blockAppleButton
            }" class="layout-flex-basis d-none ottu__sdk-flex flex-col layoutButtons ottu__sdk-center apple-pay-button-block ottu__sdk-applePay-theme">
              <span class="skeleton-loader wallet-buttons-loader">
            </div>
            <div id="${
              elements.blockGoogleButton
            }" class="layout-flex-basis d-none ottu__sdk-flex layoutButtons ottu__sdk-center google-pay-button ottu__sdk-googlePay-theme">
              <span class="skeleton-loader wallet-buttons-loader">
            </div>
            <div id="${
              elements.blockStcButton
            }" class="layout-flex-basis d-none ottu__sdk-flex layoutButtons ottu__sdk-center stc-pay-button-block ottu__sdk-stcPay-theme">
              <span class="skeleton-loader wallet-buttons-loader">
            </div>
            <div id="${
              elements.blockUrpayButton
            }" class="layout-flex-basis d-none ottu__sdk-flex layoutButtons ottu__sdk-center ur-pay-button-block ottu__sdk-urPay-theme">
              <span class="skeleton-loader wallet-buttons-loader">
            </div>
          </div>
          <div class="ottu__sdk-header2 card-only-display ottu__sdk-title-text-theme ottu__sdk-payment-methods-heading-theme">${$t(
            'Pay With'
          )}</div>
          <div class="ottu__sdk-flex layout card-only-display ${
            displayMode == 'grid'
              ? 'ottu__sdk-grid-view'
              : 'ottu__sdk-column-view'
          } ottu__sdk-methods-block-theme" >
            <div id="${
              elements.savedCards
            }" class="ottu__sdk-flex layout-flex-basis">
              ${paymentMethodLoader()}
            </div> 
            <div id="${
              elements.blockMethods
            }" class="ottu__sdk-flex layout-flex-basis">
              ${paymentMethodLoader()}
            </div>
          </div>  
          <div class="ottu__sdk-border card-only-display ottu__sdk-border-theme"></div>
          <div id="${
            elements.cardSaveBlock
          }" class="ottu__sdk-flex card-only-display layout card-save-block"></div> 
          <div id="${
            elements.feesBlock
          }" class="ottu__sdk-flex card-only-display layout"></div> 
          <div id="${elements.blockButtonsLoader}">
          <div><span class="skeleton-loader pay-button-loader"></div>
          </div>
          <div id="${
            elements.blockButtons
          }" class="layout-flex-basis ottu__sdk-flex flex-col ottu__sdk-btns ottu__sdk-pay-button-block layoutButtons" disabled></div>
        </div>
      </div>
    </div>
    <div id="${
      elements.threeDS
    }" class="d-none ottu__sdk-main ottu__sdk-main-theme ottu__sdk-threeDs ${
    displayMode == 'column' ? 'ottu__sdk-shrink' : ''
  }"></div>
    ${htmlModals()}
  `;
  return html;
};
exports.html = html;

const htmlModals = function () {
  const html = `
    <div id="${elements.modal}" class="d-none ottu__sdk-modal ${mainConfig.lang} ottu__sdk-card-removal-modal-theme"></div>
    <div id="${elements.popupModal}" class="d-none ottu__sdk-error-modal ${mainConfig.lang} ottu__sdk-info-modal-theme"></div>
    <div id="${elements.mobilePayModal}" class="d-none ottu__sdk-payment-modal ${mainConfig.lang} ottu__sdk-payment-modal-theme"></div>
    <div id="${elements.modalOverlay}" class="${mainConfig.lang} ottu__sdk-modal-overlay-theme"></div>
  `;
  return html;
};
exports.htmlModals = htmlModals;

const paymentMethodLoader = function () {
  const html = `
    <div id="${elements.blockMethodsLoader}" class="ottu__sdk-flex flex-col wd-100">
      <span class="skeleton-loader payment-methods-loader"></span>
      <span class="skeleton-loader payment-methods-loader"></span>
    </div> 
  `;
  return html;
};

const saveCardBlock = function () {
  const html = `
    <div id=${elements.savedCardsBlock} class="wd-100 mg-0-auto">
      <div>{%content%}</div>
    </div>
  `;
  return html;
};
exports.saveCardBlock = saveCardBlock;

const applePayNotSupportedHtml = function () {
  return `
    <div id="${elements.block_not_supported}" class="d-flex not-supported">This device doesn't support Apple Pay</div>
  `;
};
exports.applePayNotSupportedHtml = applePayNotSupportedHtml;

const enableCCV = function () {
  const html = `
    <div class="token{%key%} d-none ottu-sdk-cvv-block ${
      mainConfig.displayMode == 'grid' ? 'grid-view' : ''
    }">
      <div class="ottu__sdk-input-div ccv-input ottu__sdk-ccv-input-theme ${
        mainConfig.displayMode == 'grid' ? 'grid-view' : ''
      }" >
        <input type="text" class="ottu__sdk-input-ccv" autocomplete="cc-name" data-brand="{%brand%}" />
        <span class="floating-label ottu__sdk-floating-label-theme">${$t(
          'CVV'
        )}</span>
      </div>
      <span class="ottu__sdk-error2">
        <i></i>
      </span>
    </div>
    <span class="cvv-info-text ottu__sdk-secondary-text-theme ottu__sdk-cvv-info-text-theme">${$t(
      'Please Enter The Card CVV To Proceed'
    )}</span>
  `;
  return html;
};

const saveCards = function (ccv_required) {
  const html = `
    <div class="ottu__sdk-position-relative ottu__sdk-radio-div">
    <input type="radio" name="token" id="token{%key%}" token="{%token%}" submit_url="{%url%}" class="ottu__sdk-payment {%radioClass%} {%ccvClass%}" value="{%key%}" data-gateway={%gateway%} data-is_expired={%isExpired%} data-type="save" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}" data-ccv_required="{%ccv_required%}" data-is_preferred="{%is_preferred%}" />
      <label for="token{%key%}" class="ottu__sdk-saved-tokens ottu__sdk-methods-theme ottu__sdk-saved-cards-theme">
        <div class="ottu__sdk-flex flex-col wd-100">
          <span class="ottu__sdk-flex justify-start wd-100">
            <img src="{%logo%}" alt="{%alt%}" class="ottu__sdk-save-token-img" />
            <div class="ottu__sdk-flex ottu__sdk-card-number-block">
              <span class="align-center ottu__sdk-card-number-block--span">
                <span class="ottu__sdk-card-num ottu__sdk-secondary-text-theme ottu__sdk-card-number-theme">{%number%}</span>
              </span>
              <span class="ottu__sdk-expires {%expiryClass%} ottu__sdk-secondary-text-theme ottu__sdk-card-expiry-theme">{%expiry%}</span>
            </div>
          </span>
            ${ccv_required ? enableCCV() : ''}
          </div>
          <span class="${
            elements.saveCardDeleteButton
          } popupButtons ottu__sdk-delete-card-logo-theme" delete_url="{%delete_url%}" data-number="{%number%}" >${
    logoSvg.delete
  }</span>
      </label>
    </div>
  `;
  return html;
};
exports.saveCards = saveCards;

const paymentMethodBlock = function () {
  const html = `
    <div class="wd-100">
      <div class="ottu__sdk-accordion">{%content%}</div>
    </div>
  `;
  return html;
};
exports.paymentMethodBlock = paymentMethodBlock;

const paymentMethod = function () {
  const html = `
    <div id="method{%key%}" class="${elements.accordionItem} ${elements.paymentMethod} ottu__sdk-methods-theme ottu__sdk-redirect-links-theme" data-method-id="id_{%flow%}" name="{%flow%}" {%flow%}="{%code%}" can-save-card="{%savecard%}" submit_url="{%submit_url%}" redirect_url="{%redirect_url%}" payment_url="{%payment_url%}" cancel_url="{%cancel_url%}" data-type="method" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}">
      <div class="${elements.accordionItemHeader}">
        <img src="{%logo%}" alt="{%name%}"/>
        <div class="ottu__sdk-flex wd-100 justify-between">
        <span class="ottu__sdk-flex ottu__sdk-flex-column">
          <span class="ottu__sdk-payment-name ottu__sdk-secondary-text-theme ottu__sdk-payment-method-name-theme">{%name%}</span>
        </span>
        </div>
      </div>
    </div>
  `;
  return html;
};
exports.paymentMethod = paymentMethod;

const tamaraWidget = function (name, amount) {
  const html = `
    <div id="${elements.tamaraPaymentMethod}" class="${elements.tamaraWidgetBlock}">
      <span class="ottu__sdk-tamra-label ottu__sdk-secondary-text-theme ottu__sdk-tamra-label-theme">${name}</span>
      <tamara-widget amount=${amount} class="ottu__sdk-tamara-widget" type="tamara-summary" inline-variant='text' config='{"badgePosition":"right","showExtraContent":""}'></tamara-widget>
    </div>
  `
  return html;
}
exports.tamaraWidget = tamaraWidget;

const tabbyWidget = function (name, logo) {
  const html = `
    <div class="${elements.accordionItemHeader}">
      <img src="${logo}" alt="${name}"/>
      <div class="ottu__sdk-flex wd-100 justify-between">
      <span class="ottu__sdk-flex ottu__sdk-flex-column">
        <span class="ottu__sdk-payment-name ottu__sdk-secondary-text-theme ottu__sdk-payment-method-name-theme">${name}</span>
      </span>
      </div>
    </div>
  `
  return html;
}
exports.tabbyWidget = tabbyWidget;

const tamaraPaymentMethod = function (name, amount) {
  const html = `
    <div id="method{%key%}" class="${elements.accordionItem} ${elements.paymentMethod} ottu__sdk-methods-theme ottu__sdk-redirect-links-theme" name="{%flow%}" {%flow%}="{%code%}" submit_url="{%submit_url%}" data-type="method" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}">
      ${tamaraWidget(name, amount)}
    </div>
  `;
  return html;
};
exports.tamaraPaymentMethod = tamaraPaymentMethod;

const installmentsNotAvailable = function (logo, name) {
  const html = `
    <div class="${elements.installmentsNotAvailableBlock} border-radius-12 ottu__sdk-disabled ottu__sdk-methods-theme ottu__sdk-redirect-links-theme align-item--center">
      <img src="${logo}" alt="${name}" class="ottu__sdk-installment-logo"/>
      <div class="${elements.installmentsNotAvailable}"> ${$t('Installments Payment option is Not available for you')}</div>
    </div>
  `;
  return html;
};
exports.installmentsNotAvailable = installmentsNotAvailable;

const loadingInstallmentsOptions = function () {
  const html = `
    <div id="method{%key%}" class="${elements.loadingInstallmentsOptionsBlock} ${elements.paymentMethod} border-radius-10 ottu__sdk-cursor-disabled ottu__sdk-methods-theme ottu__sdk-redirect-links-theme" data-method-id="id_{%flow%}" name="{%flow%}" {%flow%}="{%code%}" submit_url="{%submit_url%}" data-type="method" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}">
      <span class="skeleton-loader flex-methods-loader"></span>
    </div>
  `;
  return html;
};
exports.loadingInstallmentsOptions = loadingInstallmentsOptions;

const buttonPay = function () {
  const html = `
    <button type="button" id="${
      elements.payButton
    }" class="ottu__sdk-pay-btn pay-button card-only-display ottu__sdk-primary-text-theme ottu__sdk-pay-button-theme">
      <span>${$payButtonText()}</span>
      <div>${logoSvg.loading}</div>
    </button>
  `;
  return html;
};
exports.buttonPay = buttonPay;

const buttonCancel = function () {
  const html = `
    <button type="button" id="${
      elements.payCancel
    }" class="ottu__sdk-cancel-btn cancel-button card-only-display ottu__sdk-primary-text-theme ottu__sdk-pay-button-theme">
      <span>${$t('Reject')}</span>
      <div>${logoSvg.greyloader}</div>
    </button>
  `;
  return html;
};
exports.buttonCancel = buttonCancel;

const buttonStc = function () {
  const html = `
    <button id="${elements.stcPayButton}" class="stc-pay-button" name="{%flow%}" {%flow%}="{%code%}" can-save-card="{%savecard%}" submit_url="{%submit_url%}" payment_url="{%payment_url%}" data-type="wallet" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}">
      <img src="${cardsSvg.stc_pay}" alt=""/>
    </button>
    `;
  return html;
};
exports.buttonStc = buttonStc;

const buttonUrPay = function () {
  const html = `
    <button id="${elements.urPayButton}" class="ur-pay-button" name="{%flow%}" {%flow%}="{%code%}" can-save-card="{%savecard%}" submit_url="{%submit_url%}" payment_url="{%payment_url%}" data-type="wallet" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}">
      <img src="${cardsSvg.urpay}" alt=""/>
    </button>
    `;
  return html;
};
exports.buttonUrPay = buttonUrPay;

const buttonApple = function (isWalletActive) {
  const html = isWalletActive
    ? `<button id="${elements.applePayButton}" lang="${
        mainConfig.apple_buttonLocale || 'en'
      }" class="apple-pay-button ottu__sdk-apple-pay-button-type ottu__sdk-apple-pay-button-style ottu__sdk-black-div"></button>`
    : `<button id="${elements.applePayButton}" lang="${
        mainConfig.apple_buttonLocale || 'en'
      }"  class="apple-pay-button ottu__sdk-apple-pay-button-type ottu__sdk-apple-pay-button-style ottu__sdk-black-div"></button>
    <span class="${
      elements.applePayUnavailableText
    } ottu__sdk-secondary-text-theme ottu__sdk-applePay-tooltip-theme">${$t(
        'Apple Pay is unavailable. Please update your device, set up Wallet, add an active card, ensure the domain is verified by Apple, and refresh the page.'
      )}</span>`;
  return html;
};
exports.buttonApple = buttonApple;

const cardRemoveModal = function () {
  const html = `
    <div class="ottu__sdk-modal-header">
      <div class="ottu__sdk-modal-title card-removal-logo">${
        logoSvg.deleteFilled
      }</div>
    </div>
    <div>
      <p class="ottu__sdk-secondary-text-theme ottu__sdk-modal-message ottu__sdk-delete-card-message-theme">
        ${$t('Are you sure you want to remove this method?')}
      </p>
      <div class="ottu__sdk-modal-btns">
      <button class="delete-button popupButtons ottu__sdk-primary-text-theme ottu__sdk-delete-card-button-theme">
        <span>${$t('Yes, Remove')}</span>
        <div>${logoSvg.whiteLoader}</div>
      </button>
      <button class="close-button popupButtons ottu__sdk-primary-text-theme ottu__sdk-keep-card-button-theme">${$t(
        'No, Keep'
      )}</button>
      </div>
    </div>
  `;
  return html;
};
exports.cardRemoveModal = cardRemoveModal;

const rejectTransactionModal = function () {
  const html = `
    <div class="ottu__sdk-modal-header">
    </div>
    <div>
      <p class="ottu__sdk-secondary-text-theme ottu__sdk-modal-message">
        ${$t('Are you sure you want to cancel the transaction?')}
      </p>
      <div class="ottu__sdk-modal-btns">
      <button id="${elements.modalYesBtn}" class="ottu__sdk-yes-button popupButtons ottu__sdk-primary-text-theme">
        <span>${$t('Yes')}</span>
        <div>${logoSvg.whiteLoader}</div>
      </button>
      <button id="${elements.modalNoBtn}" class="ottu__sdk-no-button popupButtons ottu__sdk-primary-text-theme">${$t(
        'No'
      )}</button>
      </div>
    </div>
  `;
  return html;
};
exports.rejectTransactionModal = rejectTransactionModal;


const failedModal = function (heading, buttonText) {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-close-btn popupButtons close-button ottu__sdk-popup-close-button-theme">
        ${logoSvg.modalX}
      </span>
      <span class="ottu__sdk-payment-failed ottu__sdk-primary-text-theme ottu__sdk-error-popup-heading-theme">
        ${logoSvg.failed}
        ${
          heading
            ? $t(heading)
            : $t('Payment failed...<br/>Error occurred with payment')
        }
      </span>
      <div class="ottu__sdk-modal-error-message ottu__sdk-secondary-text-theme ottu__sdk-error-popup-message-theme">{%message%}</div>
      <div class="ottu__sdk-modal-data ottu__sdk-error-popup-data-theme">{%response%}</div>
      <div class="ottu__sdk-modal-btns2 error-popup-btn ottu__sdk-primary-text-theme ottu__sdk-error-retry-button-theme">
        <button class="close-button popupButtons">${$t(buttonText || 'Try Again')}</button>
      </div>
    </div>
  `;
  return html;
};
exports.failedModal = failedModal;

const redirectModal = function () {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-redirect-message ottu__sdk-primary-text-theme ottu__sdk-redirect-popup-heading-theme">
        {%message%}
      </span>
      <div class="ottu_sdk-succes-footer">
        ${logoSvg.loader}
      </div>
    </div>
  `;
  return html;
};
exports.redirectModal = redirectModal;

const successModal = function () {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-payment-success ottu__sdk-primary-text-theme ottu__sdk-success-popup-heading-theme">
        ${logoSvg.success}
        ${$t('Your Payment is done successfully!')}
      </span>
      <span class="ottu__sdk-close-btn popupButtons close-button ottu__sdk-popup-close-button-theme">
        ${logoSvg.modalX}
      </span>
      <p class="ottu__sdk-modal-success-message ottu__sdk-secondary-text-theme ottu__sdk-success-popup-message-theme">{%message%}</p>
      <div class="ottu__sdk-modal-data ottu__sdk-secondary-text-theme ottu__sdk-success-popup-data-theme">{%response%}</div>
      <div class="ottu_sdk-succes-footer">
        ${logoSvg.loader}
      </div>
    </div>
  `;
  return html;
};
exports.successModal = successModal;

const mobilePayModal = function (canSaveCard, saveCard, type) {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-close-btn popupButtons close-button ottu__sdk-popup-close-button-theme">
        ${logoSvg.modalX}
      </span>
      <span class="ottu__sdk-mobile-number-popup-heading ottu__sdk-primary-text-theme ottu__sdk-mobile-number-popup-heading-theme">
        ${$t('Mobile Number')}
      </span>
      <div id="${
        elements.mobilePayPhoneNumberBlock
      }" class="ottu__sdk-flex flex-col mb-5 align-item--center">
        <div class="ottu__sdk-input-div2 ottu__sdk-mobile-number-input ottu__sdk-primary-text-theme ottu__sdk-mobile-number-input-theme">
          <input id="${
            elements.mobilePayPhoneNumber
          }" class="ottu__sdk-popup-input" type="text" value="{%mobileNo%}" placeholder="${$t(
    'Enter Mobile Number'
  )}"/>
        </div>
        <span class="ottu__sdk-error ottu__sdk-secondary-text-theme ottu__sdk-payment-error-message-theme">
          <i></i>
        </span>
      </div>
      ${canSaveCard === 'true' ? saveMobilePayCheckbox(saveCard, type) : ''}
      <div class="ottu__sdk-modal-btns2 ottu__sdk-primary-text-theme ottu__sdk-otp-send-button-theme">
        <button id="${
          elements.mobilePaySubmitBtn
        }" class="sendOtpBtn popupButtons">
          <span>${$t('Send OTP')}</span>
          <div>${logoSvg.whiteLoader}</div>
        </button>
      </div>
    </div>
  `;
  return html;
};
exports.mobilePayModal = mobilePayModal;

const mobilePayOTPModal = function () {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-back-button popupButtons ottu__sdk-popup-back-button-theme">
        ${logoSvg.back}
      </span>
      <span class="ottu__sdk-otp-popup-heading ottu__sdk-primary-text-theme ottu__sdk-otp-popup-heading-theme">
        ${$t('OTP')}
      </span>
      <span class="ottu__sdk-close-btn popupButtons close-button ottu__sdk-popup-close-button-theme">
        ${logoSvg.modalX}
      </span>
      <div id="${
        elements.mobilePayOTPBlock
      }" class="ottu__sdk-flex flex-col align-item--center">
        <div class="ottu__sdk-input-div2 ottu__sdk-otp-input ottu__sdk-primary-text-theme ottu__sdk-otp-input-theme">
          <input id="${
            elements.mobilePayOTP
          }" type="text" class="ottu__sdk-popup-input" placeholder="${$t(
    'Please Enter OTP'
  )}"/>
        </div>
        <span class="ottu__sdk-error ottu__sdk-secondary-text-theme ottu__sdk-payment-error-message-theme">
          <i></i>
        </span>
      </div>
      <button id="${
        elements.mobilePayResendOtpBtn
      }" class="mobilePayResendOTPBtn ottu__sdk-secondary-text-theme resendButton ottu__sdk-disabled ottu__sdk-otp-resend-button-theme">
        <span>${$t('Resend OTP')}</span>
        <div>${logoSvg.loader}</div>
      </button>
      <div class="ottu__sdk-modal-btns2 ottu__sdk-primary-text-theme ottu__sdk-otp-submit-button-theme">
      <button id="${elements.mobilePayOtpSubmitBtn}" class="mobilePayButton">
          <span>${$t('Pay')} ${
    method.amount && method.currency_code
      ? ` (${method.amount} ${method.currency_code})`
      : ''
  }</span>
          <div>${logoSvg.whiteLoader}</div>
      </button>
      </div>
    </div>
  `;
  return html;
};
exports.mobilePayOTPModal = mobilePayOTPModal;

const iframeLoader = function () {
  const html = `
    <div id="${elements.blockIframeLoader}" class="ottu__sdk-threeDs-loader">
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
      <div class="padding-50"><span class="skeleton-loader h-30"></div>
    </div>
  `;
  return html;
};
exports.iframeLoader = iframeLoader;

const saveCardCheckbox = function () {
  const html = `
  <div class="ottu__sdk-save-token-block">
    <span class="checkbox-label ottu__sdk-primary-text-theme ottu__sdk-checkbox-label-theme"> ${$t(
      'For Faster And More Secure Checkout, Save Your Card Details'
    )} </span>
    <label class="switch">
        <input id="${elements.cardSaveCheckbox}" type="checkbox" checked/>
        <span class="slider round ottu__sdk-checkbox-theme"></span>
    </label>
  </div>
  `;
  return html;
};
exports.saveCardCheckbox = saveCardCheckbox;

const saveMobilePayCheckbox = function (saveCard, type) {
  const html = `
    <div id="${
      elements.mobilePayCheckbox
    }" class="ottu__sdk-save-account-checkbox-block">
      <input
        type="checkbox"
        name="${elements.mobilePaySaveCard}"
        id="${elements.mobilePaySaveCard}"
        class="ottu__sdk-checkbox"
        ${saveCard ? 'checked' : ''}
      />
      <label for="${
        elements.mobilePaySaveCard
      }" class="ottu__sdk-secondary-text-theme ottu__sdk-save-account-label-theme">${
    type === 'urPay' ? $t('Save URPay Account') : $t('Save STC Account')
  }</label>
    </div>`;
  return html;
};
