const { sdkFetch } = require('./http');
const { Selector } = require('./selector');
const { generateSamsungOrderNo } = require('./util');
const { mainConfig, SDK_FOPS, SAMSUNG_PAY_ACTION } = require('./variables');

let samsungPayClient = null;

const _getSamsungClient = () => {
  if (samsungPayClient === null) {
    try {
      samsungPayClient = new SamsungPay.PaymentClient({
        environment: "PRODUCTION"
      });
    } catch (error) {
      console.log("Error: Unable to create Samsung Payment client", error);
      return false;
    }
  }
  return samsungPayClient;
};

const loadSamsungPay = async () => {
  const client = _getSamsungClient();
  if (client) {
    const paymentMethods = _getPaymentMethods();
    return await client
    .isReadyToPay(paymentMethods)
    .then(async (response) => {
      if (response.result) {
        return await _createSamsungButton();
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  }
  return false;
};

const _getPaymentMethods = () => {
  let paymentMethods = {};
  paymentMethods.version = '2';
  paymentMethods.serviceId = mainConfig.samsung_service_id;
  paymentMethods.protocol = 'PROTOCOL_3DS';
  paymentMethods.allowedBrands = ['visa', 'mastercard'];
  return paymentMethods;
};

const _getTransactionInfo = () => {
  let transactionInfo = {};
  transactionInfo.orderNumber = generateSamsungOrderNo(mainConfig.session_id);
  transactionInfo.merchant = {
    name: mainConfig.samsung_merchant_name,
    url: mainConfig.merchant_id,
  };
  transactionInfo.amount = {
    option: 'FORMAT_TOTAL_PRICE_ONLY',
    currency: mainConfig.samsung_currency_code,
    total: mainConfig.samsung_total_price,
  };

  return transactionInfo;
};

const _createSamsungButton = () => {
  const client = _getSamsungClient();
  return client.createButton({
    onClick: _onSamsungPayButtonClick,
    type: 'buy',
  });
};

const _onSamsungPayButtonClick = () => {
  const client = _getSamsungClient();
  const paymentMethods = _getPaymentMethods();
  const transactionInfo = _getTransactionInfo();

  client
    .loadPaymentSheet(paymentMethods, transactionInfo)
    .then(async (paymentCredential) => {
      try {
        const response = await processPayment(paymentCredential["3DS"].data);
        console.log('backend response: ', response);
        
        let paymentResult;
        if (response.status === "success") {
          paymentResult = {
            "status": "CHARGED",
            "provider": "MPGS"
          };
          Selector.callBackSuccess(response);
        } else if (response.status === "canceled") {
          paymentResult = {
            "status": "CANCELED",
            "provider": "MPGS"
          };
          Selector.callBackCancel(response);
        } else {
          paymentResult = {
            "status": "ERRED",
            "provider": "MPGS"
          };
          Selector.callBackError(response);
        }

        client.notify(paymentResult);
      } catch (error) {
        const paymentResult = {
          "status": "ERRED",
          "provider": "MPGS"
        };
        client.notify(paymentResult);
        Selector.callBackError({
          status: 'error',
          form_of_payment: SDK_FOPS.SAMSUNG_PAY,
          message: error.message || "Failed to process payment",
        });
      }
    })
    .catch((error) => {
      let paymentResult = {
        "status": "ERRED",
        "provider": "MPGS"
      };
      let message = "Failed to process payment"
      if (error === SAMSUNG_PAY_ACTION.cancel) {
        message = "User closed the Payment Request UI.",
        paymentResult = {
          "status": "CANCELED",
          "provider": "MPGS"
        };
      }

      client.notify(paymentResult);
      Selector.callBackError({
        status: 'error',
        form_of_payment: SDK_FOPS.SAMSUNG_PAY,
        message: error.message || message,
      });
    });

  window.SamsungPay = null;
};

function processPayment(token) {
  return new Promise((resolve, reject) => {
    const body = {
      samsung_pay_payload: {
        token 
      },
      amount: mainConfig.samsung_total_price,
      session_id: mainConfig.session_id,
      code: mainConfig.samsung_code,
    };

    sdkFetch(mainConfig.samsung_payment_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Merchant-Id': mainConfig.merchant_id,
        'Channel': 'API',
        Authorization: `Api-Key ${mainConfig.apiKey}`,
      },
      body: JSON.stringify(body),
    })
    .then(async (response) => {
      console.log(response);
      if (response.ok || response.approved) {
        return await response.json();
      } else {
        throw new Error(JSON.stringify(await response.json()));
      }
    })
    .then((data) => {
      resolve(data);
    })
    .catch((err) => {
      reject(JSON.parse(err.message));
    });
  });
}

exports.loadSamsungPay = loadSamsungPay;