const { sdkFetch } = require('./http');
const { Selector } = require('./selector');
const { mainConfig, SDK_formOfPayments } = require('./variables');

/**
 * Checks if Apple Pay is possible in the current environment.
 * @return {boolean} Boolean to check if Apple Pay is possible
 */

const _isWalletActive = async () => {
  try {
    return await window.ApplePaySession.canMakePaymentsWithActiveCard(
      mainConfig.apple_merchant_id
    );
  } catch (error) {
    return false;
  }
};
exports._isWalletActive = _isWalletActive;

const _applePayAvailable = function () {
  try {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
exports._applePayAvailable = _applePayAvailable;

const _openAppleWalletApp = async function (merchantIdentifier) {
  let errMessage = 'Failed to open wallet app.';
  await window.ApplePaySession.openPaymentSetup(merchantIdentifier)
    .then((response) => {
      if (!response) {
        Selector.callBackError({
          status: 'error',
          form_of_payment: SDK_formOfPayments.APPLE_PAY,
          message: errMessage,
        });
      }
    })
    .catch(function (err) {
      Selector.callBackError({
        status: 'error',
        form_of_payment: SDK_formOfPayments.APPLE_PAY,
        message: err.message || errMessage,
      });
    });
};
exports._openAppleWalletApp = _openAppleWalletApp;

const _startApplePaySession = async function () {
  const paymentRequest = {};

  let fee_description = undefined;
  if (mainConfig.apple_fee > 0) {
    fee_description = {
      label: mainConfig.apple_fee_description || 'Fee',
      amount: `${parseFloat(mainConfig.apple_fee)}`,
      type: 'final',
    };
  }
  (paymentRequest.currencyCode = mainConfig.apple_currency_code),
    (paymentRequest.countryCode = mainConfig.apple_country_code),
    (paymentRequest.merchantCapabilities =
      mainConfig.apple_merchantCapabilities &&
      mainConfig.apple_merchantCapabilities.length
        ? mainConfig.apple_merchantCapabilities
        : ['supports3DS']),
    (paymentRequest.supportedNetworks =
      mainConfig.apple_supportedNetworks &&
      mainConfig.apple_supportedNetworks.length
        ? mainConfig.apple_supportedNetworks
        : ['amex', 'masterCard', 'maestro', 'visa', 'mada']),
    (paymentRequest.supportedCountries =
      mainConfig.apple_supportedCountries &&
      mainConfig.apple_supportedCountries.length
        ? mainConfig.apple_supportedCountries
        : undefined),
    (paymentRequest.total = {
      label: mainConfig.apple_shop_name,
      amount: mainConfig.apple_amount,
      type: 'final',
    }),
    (paymentRequest.lineItems =
      fee_description &&
      mainConfig.apple_lineItems &&
      mainConfig.apple_lineItems.length
        ? [fee_description, ...mainConfig.apple_lineItems]
        : fee_description
        ? [fee_description]
        : undefined);

  var applePaySession = new window.ApplePaySession(
    mainConfig.apple_version || 6,
    paymentRequest
  );

  _handleApplePayEvents(applePaySession);
  applePaySession.begin();
};
exports._startApplePaySession = _startApplePaySession;

const _handleApplePayEvents = function (appleSession) {
  const _applePayAbort = () => {
    try {
      appleSession.abort();
    } catch (error) {
      console.log('Error: ApplePay abort', error);
    }
  };

  const _applePayCompletePayment = (result) => {
    try {
      appleSession.completePayment(result);
    } catch (error) {
      console.log('Error: ApplePay completePayment', error);
    }
  };

  const _applePayCompleteValidation = (sessionDetails) => {
    try {
      appleSession.completeMerchantValidation(sessionDetails);
    } catch (error) {
      console.log('Error: ApplePay completeMerchantValidation', error);
    }
  };

  appleSession.onvalidatemerchant = function (event) {
    _validateApplePaySession(event.validationURL, function (merchantSession) {
      if (
        merchantSession.callback_payload &&
        merchantSession.callback_payload.status == 'error'
      ) {
        _applePayAbort();
        Selector.callBackError(merchantSession.callback_payload);
      } else if (merchantSession.err !== undefined) {
        _applePayAbort();
        Selector.callBackError({
          status: 'error',
          form_of_payment: SDK_formOfPayments.APPLE_PAY,
          message: merchantSession.err,
        });
      }

      Selector.callBackPayment()
        .then(() => {
          _applePayCompleteValidation(merchantSession);
        })
        .catch(() => {
          _applePayAbort();
        });
    });
  };

  appleSession.oncancel = function (message) {
    Selector.callBackError({
      status: 'error',
      form_of_payment: SDK_formOfPayments.APPLE_PAY,
      message: message || 'Payment is cancelled by Apple',
    });
  };

  appleSession.onpaymentauthorized = function (event) {
    _performTransaction(event.payment, function (outcome) {
      if (outcome.approved) {
        _applePayCompletePayment({
          status: window.ApplePaySession.STATUS_SUCCESS,
        });
        if (outcome.callback_payload) {
          Selector.callBackSuccess(outcome.callback_payload);
        } else {
          Selector.callBackSuccess({
            status: 'success',
            message: outcome.message || '',
            ...outcome,
          });
        }
      } else if (!outcome.approved && !outcome.err) {
        const err = new window.ApplePayError('unknown');
        _applePayCompletePayment({
          status: window.ApplePaySession.STATUS_FAILURE,
          errors: [err],
        });
        if (
          outcome.callback_payload &&
          outcome.callback_payload.status == 'canceled'
        ) {
          if (
            outcome.callback_payload.payment_gateway_info.pg_name === 'kpay'
          ) {
            appleSession.abort();
          }
          Selector.callBackCancel(outcome.callback_payload);
        } else if (
          outcome.callback_payload &&
          outcome.callback_payload.status == 'error'
        ) {
          Selector.callBackError(outcome.callback_payload);
        }
      } else if (outcome.err) {
        const err = new window.ApplePayError('unknown');
        _applePayCompletePayment({
          status: window.ApplePaySession.STATUS_FAILURE,
          errors: [err],
        });
        Selector.callBackError({
          status: 'error',
          form_of_payment: SDK_formOfPayments.APPLE_PAY,
          message: outcome.err,
          ...outcome,
        });
      } else {
        _applePayCompletePayment(window.ApplePaySession.STATUS_FAILURE);
        Selector.callBackError({
          status: 'error',
          form_of_payment: SDK_formOfPayments.APPLE_PAY,
          message: outcome.message,
          ...outcome,
        });
      }
    });
  };
};

const _validateApplePaySession = function (appleUrl, callback) {
  let url = mainConfig.apple_validation_url;
  sdkFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify({
      apple_url: appleUrl,
      session_id: mainConfig.session_id,
      code: mainConfig.apple_gateway_code,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const data = await response.json();
        if (data.callback_payload) {
          return data;
        } else {
          throw new Error(response.status + ' Failed merchant validation ');
        }
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch(function (err) {
      callback({
        err,
      });
    });
};

const _performTransaction = function (details, callback) {
  sdkFetch(mainConfig.apple_payment_url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify({
      apple_pay_payload: {
        token: details,
      },
      currency_code: mainConfig.apple_currency_code,
      amount: mainConfig.apple_amount,
      session_id: mainConfig.session_id,
      code: mainConfig.apple_gateway_code,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const data = await response.json();
        if (data.callback_payload) {
          return data;
        } else {
          throw new Error(response.status + ' Failed Fetch ');
        }
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      callback({
        err,
      });
    });
};
