const Sentry = require('@sentry/browser');
const { mainConfig } = require('./variables');
const sdkFetch = async (url, options) => {
  return await window.fetch(url, options)
    .then((response) => {
      if (response.status > 400 || (response.status >= 400 && mainConfig.captureAll )) {
        Sentry.captureMessage(`Server error response status ${response.status}`);
      }
      return response;
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });
};
exports.sdkFetch = sdkFetch;

const fetchSentryConfig = async (merchant_id, apiKey) => {
  let url = process.env.SDKSTUDIO_URL || 'https://studiosdk.ottu.net/config';
  try{
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
      body: JSON.stringify({
        'merchant_id': merchant_id,
        'platform': 'web'
      })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Sentry config');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
exports.fetchSentryConfig = fetchSentryConfig;

const getPaymentDetails = (merchant_id, session_id, apiKey) => {
  return new Promise((resolve,reject) => {
    let url = `https://${merchant_id}/b/checkout/api/sdk/v1/pymt-txn/submit/${session_id}`;
  
    sdkFetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
exports.getPaymentDetails = getPaymentDetails;

const redirectPayFetch = (submit_url, apiKey, code , save_card) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
      body: JSON.stringify({
        pg_code:code,
        save_card: save_card
      })
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.redirectPayFetch = redirectPayFetch;

const sendMobilePayOtp = (submit_url, apiKey, session_id, code, customer_phone, save_card) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
      body: JSON.stringify({
        customer_phone: customer_phone,
        session_id: session_id,
        pg_code: code,
        save_card: save_card,
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.sendMobilePayOtp = sendMobilePayOtp;

const submitMobilePayOTP = (submit_url, apiKey, session_id, otp) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
      body: JSON.stringify({
        session_id: session_id,
        otp: otp,
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.submitMobilePayOTP = submitMobilePayOTP;

const tokenPayFetch = (submit_url, apiKey, merchant_id, session_id, code, cvv) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        session_id: session_id,
        payment_method:'token',
        token: code,
        cvv: cvv
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.tokenPayFetch = tokenPayFetch;

const deleteTokenFetch = (submit_url, apiKey) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'DELETE',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.deleteTokenFetch = deleteTokenFetch;

const updateTxnStatus = (merchant_id, session_id, apiKey) => {
  return new Promise((resolve,reject) => {
    let url = `https://${merchant_id}/b/checkout/api/sdk/v1/concur-payer-view/${session_id}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
        'Accept-Language': mainConfig.lang,
      }
    })
      .then(response => {
        if ((response.status > 400 && !response.status === 404) || (response.status >= 400 && mainConfig.captureAll )) {
          Sentry.captureMessage(`Server error response status ${response.status}`);
        }

        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else if (response.status !== 404){
            reject(response.json());
          }
        }
      })
  });
};
exports.updateTxnStatus = updateTxnStatus;

const fetchInstallmentOptions = async (url, apiKey, code) => {
  return new Promise((resolve,reject) => {
    sdkFetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': mainConfig.lang,
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        pg_code: code,
      })
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.fetchInstallmentOptions = fetchInstallmentOptions;

const cancelPayFetch = async (url, apiKey) => {
  return new Promise((resolve,reject) => {
    sdkFetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': mainConfig.lang,
        Authorization: `Api-Key ${apiKey}`,
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.cancelPayFetch = cancelPayFetch;